import React from 'react';
import { css } from 'glamor';

// eslint-disable-next-line react/prop-types
const Label = ({ text, error, color, style }) => {
  const defaultStyles = {
    color: color ? color : '#fff'
  };

  const s = { ...defaultStyles, ...style };
  return (
    <p
      {...css(s)}
    >
      {text}
    </p>
  );
};

export default Label;
