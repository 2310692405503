import React from 'react'
import { useSnackbar } from 'notistack'
import Chip from '@material-ui/core/Chip'
import copy from 'copy-to-clipboard'

function UserHorizontalScrollChipList({
  users, style, extraStartChips
}) {

  const { enqueueSnackbar } = useSnackbar();

  const defaultStyle = {
    margin:0,
    marginTop: '5px',
    maxHeight:'64px',
    overflowX:'scroll',
    flexDirection:'row',
    display:'flex',
    alignItems:"center",
    fontWeight:'400',
    fontSize:'20px',
    color:'rgba(0, 0, 0, 0.8)'
  }

  const s = {...defaultStyle, style}

  return (
      <div
        style={s}
      >
        {extraStartChips}
        {users.length > 0 && (
          <>
            {users && users.map((user) => (
              <Chip onClick={(e) => {
                e.stopPropagation()
                copy(user.email)
                enqueueSnackbar(`Copied ${user.email.toLowerCase()} to clipboard`)

              }} style={{marginLeft:'5px', marginTop:'3px',marginBottom:'3px'}} label={user.name} avatar={
                <img
                  alt="Logo"
                  src={user.pic ? user.pic : (`//logo.clearbit.com/${user.email.split("@")[1]}?size=24`)}
                  onError={(e) => {
                    e.target.style.width = 0
                    e.target.style.border = 'none'
                  }}
                  style={{borderRadius:'100%', objectFit:'contain'}}/>} />
            ))}
          </>
        )}
      </div>
  );
}

export default UserHorizontalScrollChipList;