import React, { useEffect, useState } from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import ChallengeCard from './ChallengeCard'
import api from '../../utils/api'
import { validate as uuidValidate } from 'uuid'
import getUserPicture from '../../utils/getUserPicture'
import capitalize from '../../utils/capitalize'
import MoonLoader from 'react-spinners/MoonLoader'
import rocketImg from '../../assets/undraw_To_the_stars_qhyy.svg'
import Button from '../../components/Button'

function Challenges({type,graph, user}) {
  const {push} = useHistory();
  const location = useLocation();
  const [challenges, setChallenges] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getChallenges() {
      setLoading(true)
      setChallenges([])
      let x;
      try {
        x =  await api.get(`/challenges?type=${type}`)
        console.log(x.data)
        let {data} = x
        data.reverse()
        const chals =[]
        //eslint-disable-next-line no-unused-vars
        for (let [i,challenge] of data.entries()) {
          if (challenge.ideas.length > 0) {
            const newIdeas = []
            for (let idea of challenge.ideas) {
              const valid = uuidValidate(idea.createdBy.adId)
              if (valid) {
                const pic = await getUserPicture({ id: idea.createdBy.adId }, api)
                idea.createdBy.pic = pic;
                newIdeas.push(idea)
              } else {
                newIdeas.push(idea)
              }
            }
            challenge.ideas = newIdeas
          }

          if (challenge.stakeholders.length > 0) {
            const newStakes = []
            for (let stakeholder of challenge.stakeholders) {
              const valid = uuidValidate(stakeholder.adId)
              if (valid) {
                const pic = await getUserPicture({ id: stakeholder.adId }, api)
                stakeholder.pic = pic;
                newStakes.push(stakeholder)
              } else {
                newStakes.push(stakeholder)
              }
            }
            challenge.stakeholders = newStakes
          }
          chals.push(challenge)
        }
        setLoading(false)
        setChallenges(chals)
      } catch (e) {
        console.log(e)
      }

    }
    getChallenges()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[type])

  return (
    <>
    <>
      <>
        <div style={{
          marginLeft:'32px',
          marginTop:'52px',
          marginRight:'32px',
          alignItems:'center',
          display:'flex'
        }}>
          <h1 style={{ color:'#2465AF',marginTop:0}}>
            {capitalize(type)} challenges
          </h1>
          {user && user.type === 'internal' && challenges.length > 0 && (
            <div style={{marginLeft:'auto', width:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
              <div onClick={() => push(location.pathname + "/new")} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'100%',height:'100%',marginTop:'4px',color:'#2465AF'}}>+</div>
            </div>
          )}


        </div>
        <div style={{
          padding:'32px',
          display:'flex',
          flexDirection:'column',
          flexFlow:'wrap'
        }}>

          {loading && (
            <>
            <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
            </>
            )}

          {challenges.length >= 1 && (
            challenges.map(challenge => (
                <ChallengeCard api={api} type={type} graph={graph} key={challenge._id} challenge={challenge}/>
              ))
          )}

          {!loading && challenges.length <= 0 && (
            <>
              <div style={{display:'flex', flexDirection:'column',marginLeft:'auto',marginRight:'auto',alignItems:'center'}}>
                <img alt="Rocket" style={{width:'250px'}} src={rocketImg}/>
                <h2 style={{fontWeight:'400'}}>No {type} challenges yet!</h2>
                {(user && user.type === 'internal') && (
                  <Button to={location.pathname + "/new"} title={`Post a ${type} challenge`}></Button>
                )}
              </div>

            </>
          )}

        </div>


      </>

    </>
      </>

  );
}

export default Challenges;
