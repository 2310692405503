import React from 'react';
import { css } from 'glamor';

// eslint-disable-next-line react/prop-types
const SmallChip = ({ style, value, img }) => {

  const defaultStyles = {
    margin:'0px',
    marginRight:'6px',
    fontSize:'12px',
    paddingLeft:'6px',
    marginTop:'6px',
    paddingRight:'6px',
    backgroundColor:'grey',
    display:'flex',
    justifyContent:'center',
    borderRadius:'10px',
    color:'white'
  };

  let s = { ...defaultStyles, ...style };

  return (
    <p
      {...css(s)}
    >
      {img ? img : null} {value}
    </p>
  );
};

export default SmallChip;
