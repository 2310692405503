import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faRoad, faTrain, faBurn, faPlane, faTint, faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@material-ui/core'
import capitalize from './capitalize'


const sectorIcon = (sector, tooltipLocation) => {
  let icon;
  if (sector === "RAIL") icon = faTrain
  if (sector === "HIGHWAYS") icon = faRoad
  if (sector === "AVIATION") icon = faPlane
  if (sector === "ENERGY") icon = faBurn
  if (sector === "WATER") icon = faTint
  if (sector === "DEFENCE") icon = faShieldAlt
  return (
      <Tooltip placement={tooltipLocation ? tooltipLocation : 'bottom'} style={{zIndex: "9999999"}} title={capitalize(sector)} aria-label={capitalize(sector)}>
        <div style={{display:'flex'}}>
        <FontAwesomeIcon style={{ marginLeft:'2px', height:'12px'}} icon={icon} />
        </div>
      </Tooltip>
  )
}

export default sectorIcon