import validateEmail from './validateEmail'

class ChipUtils {
  static addChipItem (itemToAdd, itemArray, validationPredicate, enqueueSnack) {
    let newItemArray = [...itemArray]
    console.log(typeof itemToAdd === 'string')
    if (typeof itemToAdd === 'string' &&  itemToAdd.includes(',')) {

      const x = itemToAdd.split(",")
      x.forEach((y) => {
        if (y.length > 0) newItemArray.push(y)
      })
    } else {
      newItemArray.push(itemToAdd)
    }

    // const parsedItemArray = splitCsv(itemToAdd)
    // const trimmedItems = parsedItemArray.map(item => item.trim())
    // const validItems = trimmedItems.filter(item => validationPredicate ? validationPredicate(item, enqueueSnack) : true)
    newItemArray = newItemArray.filter((v, i) => newItemArray.indexOf(v) === i)
    return newItemArray
  };

  //this is not very nice, as I'm sure you will discover
  static deleteChipItem (chip, itemArray) {
    console.log(itemArray)
    console.log(chip)
    if (chip._id) {
      return (itemArray[0]._id ||itemArray[0].adId) ? itemArray.filter(item => item._id !== chip._id) : itemArray.filter(item => item !== chip._id)
    }
    let t = itemArray.filter(item => item !== chip)
    return t

  };

  static deleteItem (chip, itemArray) {
    console.log(chip)
    console.log(itemArray)
    let indexToRemove;
    if (validateEmail(chip)) {
      indexToRemove = itemArray.indexOf(chip)
    } else {
      indexToRemove = itemArray.indexOf(chip.adId)
    }
    console.log(indexToRemove)
    console.log(itemArray)
    itemArray.splice(indexToRemove,1)
    return itemArray
  };
}

export default ChipUtils
