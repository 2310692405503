import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Input from '../components/Input'
import arti1 from '../assets/arti1.png'
import arti2 from '../assets/arti2.png'
import logo from '../assets/logo.png'
import axios from 'axios'
import InputEnterButton from '../components/InputEnterButton'
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '../components/Button'
import MoonLoader from 'react-spinners/MoonLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Login({graph, graphProfile, onSignIn, account}) {
  const [signup,setSignup] = useState(false)
  const [company, setCompany] = useState({})
  const [companySet, setCompanySet] = useState(false)
  const [checking, setChecking] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [signingUp, setSigningUp] = useState(false)
  const [invited, setInvited] = useState(false)
  const {pathname} = useLocation()
  useEffect(() => {
    if (account) {
      console.log("We have an account")
    }
  },[account])

  const validateEmail = (emailAddress) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailAddress).toLowerCase());
  };

  const sendInvite = async() => {
    setSigningUp(true)
    try {
      const invite = await axios.post(`${process.env.REACT_APP_API_URL}/user/invite`, {
        name,
        email,
        company: company.name
      })
      setInvited(true)
      console.log(invite)
    } catch (e) {
      console.log(e)
    }

    setSigningUp(false)
  }

  const getCompanies = async(domain) => {
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/companies`)
    let x = data.filter(x => (x.domain === domain))
    if (x.length > 0 && x[0].name)  {
      setCompany(x[0])
      setCompanySet(true)
    } else {
      setCompanySet(false)
      setCompany({})
    }
    console.log(x[0])
  }

  const checkExists = async (email) => {
    setChecking(true)
    const domain = email.split('@')[1]
    if (!validateEmail(email))  {
      setChecking(false)
      return
    }
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/email/${email}`)
    if (data.pending) {
      setInvited(true)
      setChecking(false)
      return
    }
    if (data.exists) return onSignIn({email,redirect:pathname})
    await getCompanies(domain);
    setSignup(true)
    setChecking(false)
    console.log(data.exists)
  }
  return (
      <div style={{width:'100%',height:'100%'}}>
        <img alt="" style={{position:'absolute',right:'0', height:'100%'}}  src={arti1}/>
        <img alt="" style={{position:'absolute',right:'0',height:'100%'}}  src={arti2}/>
        <img alt='Costain Logo' style={{position:'absolute', width:'104px', height:'78px',right:'0',margin:'40px'}}  src={logo}/>

          <div style={{display:'flex',flexDirection:'column', marginLeft:'auto',marginRight:'auto', width:'100%'}}>
            {(!invited) && (
              <>
            <h2 style={{
            marginTop:'200px',
            textAlign:'center',
            zIndex:'9999'
          }}>Innovation Portal</h2>
          <Input
            value={email}
            autoFocus
            onChange={(e) =>{
              setEmail(e.target.value)
              setCompanySet(false)
              setCompany({
                name:""
              })
              setSignup(false)
            }}
            onKeyDown={(async (e) => {
            if (e.keyCode === 13) await checkExists(e.target.value);
          })}
            endAdorn={
            (!signup && !checking) ? (<InputEnterButton onClick={async(e) => await checkExists(email)} style={{opacity: email.length > 0 ? 1 : 0.3, marginTop:'6px'}} icon={faArrowRight}/>) : (checking) && (<MoonLoader css={{ marginTop:'6px'}} size={16}/>)
          }
            inputStyle={{border:'1px solid rgba(0,0,0,0.6)', height:'42px'}}
            style={{ zIndex:'9999' }} placeholder='Email Address'/>
                {signup && (
                  <>
                      <Input
                        startAdorn={
                          (company.domain && company.name) && <img alt={`${company.name} logo`} style={{width:'22px'}} src={`//logo.clearbit.com/${company.domain}`}/>
                        }
                        disabled={companySet} onChange={(e)=>setCompany({name:e.target.value})} value={company.name}  inputStyle={{border: companySet ? '1px solid rgba(0,0,0,0.2)' : '1px solid rgba(0,0,0,0.6)',height:'42px'}} style={{ zIndex:'9999',marginTop:'10px'}} placeholder='Company (optional)'/>
                    <Input onChange={(e) => setName(e.target.value)} inputStyle={{border: '1px solid rgba(0,0,0,0.6)',height:'42px'}} style={{ zIndex:'9999',marginTop:'10px'}} placeholder='Name'/>
                    <Button onClick={sendInvite} loading={signingUp} style={{width:'340px', maxWidth:'340px'}} title="Sign up"/>
                  </>
                )}

             </>
            )}
          {(invited) && (
            <div style={{textAlign:'center', marginTop:'200px', zIndex:'1000001'}}>
              <FontAwesomeIcon icon={faCheckCircle}/>
              <h3 style={{marginBottom:'10px',marginTop:'10px'}}>Invite sent</h3>
              <p style={{marginTop:'3px'}}>Check your email</p>
              <Link onClick={() => {
                setInvited(false)
                setSignup(false)
                setEmail('')
              }}>Login</Link>
            </div>
          )}
        </div>

      </div>
  );
}

export default Login;
