import React from 'react';
import { css } from 'glamor';
import MoonLoader from 'react-spinners/MoonLoader';
import { useHistory } from 'react-router-dom';

function FilterButton({
  loading,
  onClick,
  title,
  style,
  textStyle,
  mouseOver,
  mouseLeave,
  icon,
  lowercase,
  to,
  type,
  disabled
}) {

  const defaultStyles = {
    fontSize:'18px',
    padding: '4px 8px',
    border:'1px solid rgba(101, 97, 125, 0.8)',
    borderRadius: '3px',
    margin: '5px',
    transition: 'all 0.2s',
    whiteSpace:'noWrap',
    cursor:'pointer'
  };
  //
  const defaultTextStyle = {
    margin: 'auto',
    fontSize: '18px',
    display: 'inherit',
    color:' rgba(101, 97, 125, 0.8)',
  };

  const s = { ...defaultStyles, ...style };
  const ts = { ...defaultTextStyle, ...textStyle };

  const defaultMouseOver = (e) => {
    //e.target.style.background = '#2465AF'
  };

  const defaultMouseLeave = (e) => {
   // e.target.style.background = '#2465AF'
  };
  const { push } = useHistory();

  return (
    <div
      type="submit"
      style={{ outline: 0 }}
      onClick={(e) => {
        onClick && onClick(e);
        push(to);
      }}
      {...css(s)}
      onMouseOver={mouseOver || defaultMouseOver}
      onMouseLeave={mouseLeave || defaultMouseLeave}
    >
      <div
        onMouseOver={(e) => e.stopPropagation()}
        onMouseLeave={(e) => e.stopPropagation()}
        style={{ display: 'inherit', margin: 'auto' }}
      >
        {loading && (
          <MoonLoader css={{ margin: 0, marginRight: '6px' }} size={16} />
        )}
        {icon && <img alt="Icon" src={icon} style={{ marginRight: '6px' }} />}
        <p {...css(ts)}>
          {!lowercase && title.toString().toUpperCase()} {lowercase && title}
        </p>
      </div>
    </div>
  );
}

export default FilterButton;