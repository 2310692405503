import React, { useCallback, useEffect, useState } from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'
import Select from '../../components/Select'
import Button from '../../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChipUtils from '../../utils/ChipUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle, faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles'
import getBase64 from '../../utils/getBase64'
import asyncForEach from '../../utils/asyncForEach'
import getUserPicture from '../../utils/getUserPicture'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import addChipSearchInputItem from '../../utils/addChipSearchInputItem'
import TextArea from '../../components/TextArea'
import IdeaCard from '../Ideas/IdeaCard'


const useStyles = makeStyles((theme) => ({
  txt: {
    minHeight: '36px',
    maxWidth:'800px',
    width:'100%',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: "#fff",
    lineHeight: '22px',
    paddingLeft: '8px',
    color: 'rgba(0, 0, 0, 0.8)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
    ':focus': {
      border: '1px solid #DCEAF5',
    },
    boxShadow:'rgb(232, 232, 233) 15px 4px 150px'
  },
}));

function NewProject({update, graph, api, graphProfile, user}) {
  const classes = useStyles();
  const {id} = useParams();
  const {push} = useHistory()

  const [title, setTitle] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [type, setType] = useState('STANDARD')
  const [tags, setTags] = useState([])
  const [stakeholders, setStakeholders] = useState([])
  //eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(0)
  const [leads, setLeads] = useState('')
  const [aims, setAims] = useState([])
  const [start,setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [fundingSource, setFundingSource] = useState('')
  const [objectives, setObjectives] = useState('')
  const [stakeholderChips, setStakeholderChips] = useState([])
  const [stakeholderSelect, setStakeholderSelect] = useState(false)
  const [searchStakeholders, setSearchStakeholders] = useState([])
  const [leadChips, setLeadChips] = useState([])
  const [leadSelect, setLeadSelect] = useState(false)
  const [searchLeads, setSearchLeads] = useState([])
  const [supportingDocumentation, setSupportingDocumentation] = useState([])
  const [coverImage, setCoverImage] = useState({})
  const [muralLink, setMuralLink] = useState('')
  const [displayedRelIdeas, setDisplayedRelIdeas] = useState([])
  const [relIdeas, setRelIdeas] = useState([])
  const [aimTextValue, setAimTextValue] = useState('')
  const [objectiveName, setObjectiveName] = useState('')
  const [objectiveDate, setObjectiveDate] = useState('')
  const [uploadingDocs, setUploadingDocs] = useState(false)
  const [uploadingCover, setUploadingCover] = useState(false)
  const [projReqPending, setProjReqPending] = useState(false)

  // useEffect(() => {
  //   const getIdeas = async() => {
  //     const ideas = await api.get(`/challenges?meta=true&search=${challengeText}`)
  //     setIdeas(ideas)
  //   }
  //   getIdeas()
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  useEffect(() => {
    if (!update && graphProfile) addChipSearchInputItem(graphProfile.id,setLeadSelect,setSearchLeads,setLeadChips,leadChips,leads,setLeads,api)
    if (update) {
      const getProject = async() => {
        const {data} = await api.get(`/projects/${id}`)
        console.log(data)
        setTitle(data.title)
        setSupportingDocumentation(data.supportingDocumentation)
        setType(data.type)
        setCoverImage(data.coverImage)
        setMuralLink(data.muralLink)
        setStakeholders(data.stakeholders.map(x => x.adId ? x.adId : x._id))
        setValue(data.value)
        setRelIdeas(data.relatedIdeas)
        setShortDescription(data.shortDescription)
        setLeads([data.lead.adId])
        setStart(new Date(data.start))
        setEnd(new Date(data.end))
        setAims(data.aims)
        setObjectives(data.objectives)
        setTags(data.tags)
        console.log(data.stakeholders)
        await initStakeholders(data.stakeholders)
        await initLead(data.lead)
      }
      getProject()
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[graphProfile])

  const getRelatedIdeas = useCallback(async(ts) => {
      let q = ""
      ts.forEach((t,i) =>  i < ts.length ? q = q.concat(t + ",") : q = q.concat(t))
      const relIdeas = await api.get(`/ideas?search=${q}`)
      setDisplayedRelIdeas(relIdeas.data.results)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[tags])

  useEffect(() => {
    if (tags.length > 0){
      getRelatedIdeas(tags)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  const initLead = async (lead) => {
    let chips= []
    if (lead.adId) {
      const pic = await getUserPicture({id: lead.adId},api)
      console.log(pic)
      lead.pic = pic;
      chips = ChipUtils.addChipItem(lead, chips)
    } else {
      chips = ChipUtils.addChipItem(lead, chips)
    }
    setLeadChips(chips)
  }

  const initStakeholders = async (stks) =>{
    console.log(stks)
    let chips = []
    for (let stkhldr of stks) {
      console.log(stkhldr)
      if (stkhldr.adId) {
        const pic = await getUserPicture({id: stkhldr.adId},api)
        console.log(pic)
        stkhldr.pic = pic;
        chips = ChipUtils.addChipItem(stkhldr, chips)
      } else {
        chips = ChipUtils.addChipItem(stkhldr, chips)
      }
      console.log(chips)
      setStakeholderChips(chips)
    }
  }

  const searchUsers = async ({search, selectSetter, itemSearchSetter}) => {
    selectSetter(true)
    if (search.length <= 2) return
    try {
      const res = await graph.get(`/users?$search="displayName:${search}"&$orderby=displayName&$count=true`)
      let users = [];
      for (let user of res.data.value) {
        users.push(user)
      }
      itemSearchSetter(users)

    } catch (e) {
      console.log(e)
    }

  }

  const uploadSupportingDocs = async (e) => {
    setUploadingDocs(true)
    const files = []
    const f = e.target.files

     let docs = await getBase64(e.target.files)
     docs = docs.map(doc => doc.split(",")[1])
     await asyncForEach(f, async(file,i) => {
        const res = await api.post('/file',{
          filename:file.name,
          data:docs[i]
        })
        files.push({
          name: file.name,
          type: file.type,
          link: res.data.url
        })
    })
    setUploadingDocs(false)
    setSupportingDocumentation([...supportingDocumentation,...files])
  }

  const uploadCoverImage = async (e) => {
    setUploadingCover(true)
    const f = e.target.files[0]
    let docs = await getBase64(e.target.files)
    docs = docs.map(doc => doc.split(",")[1])
    console.log(docs)
    await asyncForEach(docs, async(file,i) => {
      const res = await api.post('/file',{
        filename:f.name,
        data:docs[i],
        type: f.type
      })
      console.log(f)
      setCoverImage({
        name: f.name,
        type: f.type,
        link: res.data.url
      })
    })
    setUploadingCover(false)


  }


  const postProject = async() => {
    setProjReqPending(true)
    const relIdeasIds = relIdeas.map(x => x._id)
    let x = {
      createdBy: graphProfile.id,
      title,
      type,
      tags,
      fundingSource,
      stakeholders,
      start,
      value,
      relatedIdeas: relIdeasIds,
      shortDescription,
      end,
      aims,
      lead:leads[0],
      objectives,
      muralLink,
      supportingDocumentation,
      coverImage: coverImage ? coverImage: null
    }
    Object.keys(x).forEach((key) => (x[key] == null) && delete x[key]);
    console.log(x)
    try {
      const project = await api.post('/projects',x)
      console.log(project)
      push(`/projects/${project.data._id}`)
    } catch (e) {
      console.log(e)
    }
    setProjReqPending(false)

  }

  const updateProject = async() => {
    setProjReqPending(true)
    const relIdeasIds = relIdeas.map(x => x._id)

    const x = {
      title,
      shortDescription,
      type,
      tags,
      fundingSource,
      stakeholders,
      start,
      end,
      aims,
      value,
      relatedIdeas:relIdeasIds,
      lead:leads[0],
      objectives,
      muralLink,
      supportingDocumentation,
      coverImage: coverImage ? coverImage: null
    }
    Object.keys(x).forEach((key) => (x[key] == null) && delete x[key]);
    console.log(x)
    let project;
    try {
      project = await api.put(`/projects/${id}`,x)
      push(`/projects/${project.data._id}`)
    } catch (e) {
      console.log(e.response)
    }
    setProjReqPending(false)

  }

  return (
    <>
      <div style={{
        display:'flex',
        marginLeft:'32px',
        marginTop:'52px',
        marginRight:'32px',
        alignItems:'center'
      }}>
        <h1 style={{ color:'#2465AF'}}>
          {!update ? `New Project`: `Edit Project`  }
        </h1>
      </div>
      <div style={{padding:'32px', display:'flex',flexDirection:'column'}}>
        <Label  color="rgba(0, 0, 0, .8)" text="Title"/>
        <Input endAdorn={<p style={{color:'rgba(0,0,0,0.6)'}}>{90 - title.length}</p>} style={{alignSelf:'left'}} inputStyle={{maxWidth:'800px', width:'100%'}} value={title} onChange={(e) => {if(e.target.value.length <= 90)  setTitle(e.target.value)}} placeholder="Title your project (in less than 90 characters)" />
        <Label  color="rgba(0, 0, 0, .8)" text="Short Description"/>
        <TextArea rows="4" endAdorn={<p style={{color:'rgba(0,0,0,0.6)'}}>{280 - shortDescription.length}</p>} style={{alignSelf:'left'}} inputStyle={{maxWidth:'800px', width:'100%'}} value={shortDescription}
                  onChange={(e) => {
                    if(e.target.value.length <= 280) {
                    setShortDescription(e.target.value)
                    } else {
                    setShortDescription(e.target.value.slice(0,280))
                  }
                  }} placeholder="Describe your project (in less than 280 characters)" />
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Type"/>
        <Select
          styles={{width:'150px'}}
          onChange={(e) => {
            setType(e.target.value)
            if (e.target.value ==="TRAIL") {}
            setFundingSource("Existing Contract")
          }}
          value={type}
          options={
          <>
            <option value="STANDARD">Standard</option>
            <option value="TRIAL">Site Trial</option>
            <option value="ACADEMIC">Academic</option>
          </>
        }
        placeholder="Type"></Select>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Start Date"/>
        <DatePicker dateFormat="dd/MM/yyyy"  placeholderText="Enter project start date" customInput={<Input/>} selected={start} onChange={setStart} />
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="End Date"/>
        <DatePicker dateFormat="dd/MM/yyyy"  placeholderText="Enter project end date" customInput={<Input/>} selected={end} onChange={setEnd} />
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Aims"/>
        <div>
          {aims.length > 0 && (
            <>
              {aims.map((aim) => (
                <div style={{display:'flex'}}>
                  <div style={{display:'flex', alignItems:'center',paddingLeft:'40px'}}>
                    <FontAwesomeIcon color='#930041' onClick={() => setAims(aims.filter(x => x !== aim ))} icon={faMinusCircle} style={{marginRight:'30px', cursor:'pointer'}}/>
                  </div>
                  <p style={{marginTop:'5px',marginBottom:'5px'}}>{aim}</p>
                </div>

               ))}
            </>
          )}
          <div style={{display:'flex', maxWidth:'800px'}}>
            <Input onChange={(e)=>setAimTextValue(e.target.value)} value={aimTextValue} placeholder="Describe outcomes/aims for your project" inputStyle={{ width:'100%'}} style={{flex:5, paddingRight:'10px'}} onKeyDown={ (e) => { if (e.keyCode === 13 && e.target.value.length > 0)  {
              setAims([...aims,e.target.value])
              setAimTextValue("")
            }} } />
            <Button onClick={ (e) => {
              if (aimTextValue.length > 0) {
                setAims([...aims,aimTextValue])
                setAimTextValue("")
              }
            }}   title="Add Aim"/>
          </div>

        </div>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Objectives"/>
        <div style={{maxWidth:'800px'}}>
          {objectives.length > 0 && (
            <ul style={{marginTop:'2px'}}>
              {objectives.map((objective) => (
                <div style={{display:'flex', alignItems:'center',marginTop:'10px'}}>
                  <FontAwesomeIcon color='#930041' onClick={() => setObjectives(objectives.filter(obj => obj.name !== objective.name))} icon={faMinusCircle} style={{marginRight:'30px', cursor:'pointer'}}/>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <h3 style={{margin:0,marginLeft:'10px'}}>{objective.name}</h3>

                  <p style={{margin:0, marginLeft: '10px', fontSize:'12px'}}> {objective.due && (
                    <>
                      Due Date: {new Date(objective.due).toLocaleDateString()}
                    </>
                  )} </p>
                </div>
                </div>
              ))}
            </ul>
          )}
          <div style={{display:'flex',marginTop:'20px'}}>
            <div style={{display:'flex',flexDirection:'column', flex:5, marginRight:'8px'}}>
              <Input onChange={(e)=>setObjectiveName(e.target.value)} value={objectiveName} placeholder="Describe objectives that will achieve the aims" inputStyle={{ width:'100%'}} style={{flex:5, width:'100%', marginTop:'8px'}} onKeyDown={ (e) => { if (e.keyCode === 13 && e.target.value.length > 0)  {
                setObjectives([...objectives,{due: objectiveDate instanceof Date ? objectiveDate : null, name: objectiveName}])
                setObjectiveName("")
                setObjectiveDate("")
              }} } />
              <DatePicker dateFormat="dd/MM/yyyy"  placeholderText="Due date"  customInput={<Input style={{width: '150px', marginTop:'8px'}}/>} selected={objectiveDate} onChange={setObjectiveDate} />
            </div>

            <Button onClick={ (e) => {
              if (objectiveName.length > 0) {
                setObjectives([...objectives,{due: objectiveDate instanceof Date ? objectiveDate : null, name: objectiveName}])
                setObjectiveName("")
                setObjectiveDate("")
              }
            }}   title="Add Objective"/>
          </div>

        </div>
        {/*<Input styles={{maxWidth:'800px', width:'100%'}} placeholder="Enter the challenge stakeholders" />*/}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Themes / Tags"/>
        <ChipInput
          fullWidth
          value={tags}
          clearInputValueOnChange={true}
          onUpdateInput={(e) => {
            if (e.target.value.includes(",")) {
                setTags(ChipUtils.addChipItem(e.target.value,tags))
            }
          }}
          onAdd={(chip) => setTags(ChipUtils.addChipItem(chip,tags))}
          placeholder="Categorise your project with themes and tags eg. Automation, Digital Twin, AR (Comma seperated)"
          disableUnderline={true}
          className={classes.txt}
          name='tags'
          onDelete={(chip) => {
            console.log(chip)
            setTags(ChipUtils.deleteChipItem(chip, tags))
          }}
        >
        </ChipInput>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Value"/>
        <Input min="0.00" step="0.01" value={value ? value.amount: 0} onChange={(e) => setValue({amount:e.target.value,currency:'GBP'})} style={{ width: '250px', alignSelf:'left' }} type="number"
               placeholder="Enter the project value"/>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Funding Source"/>
         <Input  value={fundingSource} onChange={(e) => setFundingSource(e.target.value)}  inputStyle={{width:'100%'}} style={{maxWidth:'800px', width:'100%', alignSelf:'left'}} placeholder="Where is funding for this project coming from? (Visible to innovation team only)" />

        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Project Lead"/>
        <ChipInput
          style={{width:"300px"}}
          disabled={leadChips.length >= 1}
          fullWidth
          onUpdateInput={(e) => searchUsers({search: e.target.value, selectSetter: setLeadSelect, itemSearchSetter: setSearchLeads})}
          chipRenderer={({chip}) => {
            return (
              <Chip
                key={chip.id || chip._id}
                style={{marginLeft:'3px',marginRight:'3px', backgroundColor: '#2465AF'}}
                avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                label={chip.name || chip.displayName || chip}
                color="primary"
                onDelete={() =>{
                  setLeadChips(ChipUtils.deleteChipItem(chip,leadChips))
                  setLeads(ChipUtils.deleteItem(chip,leads))
                }}
                deleteIcon={<FontAwesomeIcon icon={faTimesCircle}/>}
              />
            )
          }
          }
          onAdd={(chip) => addChipSearchInputItem(chip,setLeadSelect,setSearchLeads, setLeadChips, leadChips,leads, setLeads, api)}
          value={leadChips}
          placeholder="Enter the project lead"
          disableUnderline={true}
          className={classes.txt}
          name='owners'
          dataSource={leadChips}
          dataSourceConfig={{name:'name',pic:'pic'}}
        >
        </ChipInput>
        {leadSelect && (
          <div style={{
            width: '100%',
            maxWidth: '800px',
            backgroundColor: 'white',
            height: '200px',
            boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
            overflow: 'scroll',
          }}>
            {searchLeads.map(lead => (
              lead.mail && (
                <div
                  key={lead.id}
                  onClick={() => addChipSearchInputItem(lead.id,setLeadSelect,setSearchLeads, setLeadChips, leadChips,leads, setLeads, api)} style={{
                  width: '100%',
                  borderBottom: '1px solid rgb(232, 232, 233)',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor:'pointer'
                }}>
                  <p
                    style={{ marginLeft: '8px' }}>{lead.displayName}{lead.mail && (` (${lead.mail.toLowerCase()})`)}</p>
                </div>
              )

            ))}

          </div>
        )}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Stakeholders"/>
        <ChipInput
          disabled={(user && user.type === 'external')}
          fullWidth
          onUpdateInput={(e) => searchUsers({search: e.target.value, selectSetter: setStakeholderSelect, itemSearchSetter: setSearchStakeholders, api})}
          chipRenderer={({chip}) => {
            return (
              <Chip
                key={chip.id || chip._id}
                style={{marginLeft:'3px',marginRight:'3px', marginTop:'3px', marginBottom:'3px', backgroundColor: '#2465AF'}}
                avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                label={chip.name || chip.displayName || chip}
                color="primary"
                onDelete={() =>{
                  setStakeholderChips(ChipUtils.deleteChipItem(chip,stakeholderChips))
                  setStakeholders(ChipUtils.deleteItem(chip,stakeholders))
                }}
                deleteIcon={<FontAwesomeIcon icon={faTimesCircle}/>}
              />
            )
          }
          }
          value={stakeholderChips}
          placeholder="Enter the project stakeholder/s"
          disableUnderline={true}
          className={classes.txt}
          name='owners'
          dataSource={stakeholderChips}
          onAdd={(chip) => addChipSearchInputItem(chip, setStakeholderSelect, setSearchStakeholders, setStakeholderChips, stakeholderChips, stakeholders, setStakeholders, api)}
        >
        </ChipInput>
        {stakeholderSelect && (
          <div style={{
            width: '100%',
            maxWidth: '800px',
            backgroundColor: 'white',
            height: '200px',
            boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
            overflow: 'scroll',
          }}>
            {searchStakeholders.map(stakeholder => (
              stakeholder.mail && (
                <div
                  key={stakeholder.id}
                  onClick={() => addChipSearchInputItem(stakeholder.id, setStakeholderSelect, setSearchStakeholders, setStakeholderChips, stakeholderChips, stakeholders, setStakeholders,api)} style={{
                  width: '100%',
                  borderBottom: '1px solid rgb(232, 232, 233)',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor:'pointer'
                }}>
                  <p
                    style={{ marginLeft: '8px' }}>{stakeholder.displayName}{stakeholder.mail && (` (${stakeholder.mail.toLowerCase()})`)}</p>
                </div>
              )

            ))}

          </div>
        )}
          <Label style={{}} color="rgba(0, 0, 0, .8)" text="Mural Link"/>
          <Input value={muralLink} onChange={(e) => setMuralLink(e.target.value)} style={{maxWidth:'800px', width:'100%', alignSelf:'left'}} placeholder="https://app.mural.co/"/>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Supporting Documents"/>
        {supportingDocumentation.length > 0 && supportingDocumentation.map(x => (
          <div style={{padding:'5px',margin:"5px"}}>
           <FontAwesomeIcon color="#930041" onClick={() => setSupportingDocumentation(supportingDocumentation.filter(y => y.link !== x.link ))}  style={{marginRight:'5px', cursor:'pointer'}} icon={faMinusCircle}/> {x.name}
          </div>
        ))}
        <Button loading={uploadingDocs} icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>} title="Upload Files" f="docs"  style={{marginLeft:'5px',marginTop:'10px'}} />
        <input hidden id="docs" onChange={(e) => {
          uploadSupportingDocs(e)
        }} multiple type="file"/>


        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Cover Image"/>
        {coverImage.link && (
          <div style={{padding:'5px',margin:"5px"}}>
            <FontAwesomeIcon color='#930041' onClick={() => setCoverImage({})}  style={{marginRight:'5px', cursor:'pointer'}} icon={faMinusCircle}/> {coverImage.name}
          </div>
        )}

        <Button loading={uploadingCover} icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>} title="Upload Image" f="cover"  style={{marginLeft:'5px',marginTop:'10px'}} />
        <input hidden id="cover" onChange={(e) => uploadCoverImage(e)} style={{ marginBottom: '10px'}} type="file"/>

        {displayedRelIdeas.length > 0 && (
          <>
          <Label style={{marginBottom:'3px'}} color="rgba(0, 0, 0, .8)" text="Related Ideas"/>
            {relIdeas.length > 0 && relIdeas.map((idea) => (
              <>
                <IdeaCard graph={graph} api={api} idea={idea} actionOnClick={() => setRelIdeas(relIdeas.filter(x => x._id !== idea._id))} action={
                  <>
                    <FontAwesomeIcon color={'#930041'}  style={{width:'20px',marginTop:'8px', cursor:'pointer'}} icon={faMinusCircle}/>
                  </>
                }/>
              </>

            ))}

          <p style={{fontSize:'11px', marginTop:'30px', textAlign: 'center'}}>We found these ideas that might be relevant to your project (try updating your tags for more results)</p>
            <div style={{alignSelf:'center', marginTop:'10px', webkitMaskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)', paddingTop:'20px', paddingBottom:'30px' , maxHeight: '360px',overflowY:'scroll', width:'88%', paddingLeft:'50px',paddingRight:'50px',marginLeft:'-50px', marginRight:'-50px'}}>
              {displayedRelIdeas.map(idea => (
                <IdeaCard graph={graph} api={api} idea={idea} add={true} actionOnClick={() => {
                  setRelIdeas([...relIdeas,idea])
                  setDisplayedRelIdeas(displayedRelIdeas.filter(x => x._id !== idea._id))
                }} action={
                  <>
                  <FontAwesomeIcon color={'#215732'}  style={{width:'20px',marginTop:'8px', cursor:'pointer'}} icon={faPlusCircle}/>
                  </>
                  }  />
              ))}
            </div>
          </>
        )}

        <Button loading={projReqPending} variant="primary" onClick={() => update ? updateProject() : postProject()} style={{marginLeft:'0', marginTop:'35px'}} title={!update ? "Create Project" : "Update Project"}/>
      </div>

    </>

  );
}

export default NewProject;
