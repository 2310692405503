import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import Challenges from './views/Challenges/Challenges'
import NewChallenge from './views/Challenges/NewChallenge'
import Challenge from './views/Challenges/Challenge'
import NewIdea from './views/Ideas/NewIdea'
import Idea from './views/Ideas/Idea'
import AuthProvider from './AuthProvider'
import Ideas from './views/Ideas/Ideas'
import useWindowDimensions from './utils/useWindowDimensions'
import Login from './views/Login'
import MoonLoader from 'react-spinners/MoonLoader'
import Projects from './views/Projects/Projects'
import Project from './views/Projects/Project'
import NewProject from './views/Projects/NewProject'

function App({account,onSignOut,onSignIn, graphProfile, user, apiKey, graphKey, api,graph, loggingIn}) {
  const { width } = useWindowDimensions();
  const [isMobile,setIsMobile] = useState(false)
  const {push} = useHistory()
  useEffect(()=>{
    if (apiKey) {
      const r = localStorage.getItem('redirect')
      localStorage.setItem('redirect','/')
      if (r !== '/') {
        push(r)
      }
    }
   console.log(apiKey)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[apiKey])

  useEffect(()=>{
    console.log(graphKey)
  },[graphKey])

  useEffect(() => {
    if (width <= 900) {
      setIsMobile(true);
    }

    if (width > 900) {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <div style={{display:'flex', width:'100vw', height:'100vh'}}>
      {account && (
        <Sidebar graphProfile={graphProfile} logout={onSignOut} isMobile={isMobile}/>
      )}
      <div
        id="scrollable"
        style={{width:'100%', height:'100%',overflow:'scroll', zIndex:'99'}}>
        {(!apiKey && !loggingIn) && (
          <Login account={account} onSignIn={onSignIn} graphProfile={graphProfile} api={api} graph={graph}/>
        )}
        {(!apiKey && loggingIn) && (
          <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
        )}

        {apiKey && (
          <Switch>
            <Route exact path="/ideas">
              <Ideas user={user} graphProfile={graphProfile} api={api} graph={graph}/>
            </Route>
            <Route exact path="/ideas/new/:challengeId">
              <NewIdea user={user}  graphProfile={graphProfile} api={api} graph={graph}/>
            </Route>
            <Route exact path="/ideas/new">
              <NewIdea graphProfile={graphProfile} user={user} api={api} graph={graph}/>
            </Route>
            <Route exact path="/ideas/:id/edit">
              <NewIdea user={user}  graphProfile={graphProfile} api={api} graph={graph} update={true}/>
            </Route>
            <Route exact path="/ideas/:id">
              <Idea user={user} graphProfile={graphProfile} graph={graph} api={api}/>
            </Route>
            <Route exact path="/challenges/client">
              <Challenges api={api} user={user}  graph={graph} type='client'/>
            </Route>
            <Route exact path="/challenges/client/new">
              <NewChallenge user={user}  api={api} graph={graph} type='client'/>
            </Route>
            <Route exact path="/challenges/client/:id">
              <Challenge user={user}  graphProfile={graphProfile} graph={graph} api={api}/>
            </Route>
            <Route exact path="/challenges/contract">
              <Challenges api={api} user={user}  graph={graph} type='contract'/>
            </Route>
            <Route exact path="/challenges/contract/new">
              <NewChallenge user={user} api={api} graph={graph} graphKey={graphKey} type='contract'/>
            </Route>
            <Route exact path="/challenges/contract/:id">
              <Challenge user={user} graphProfile={graphProfile} graph={graph} api={api}/>
            </Route>
            <Route exact path="/challenges/business">
              <Challenges user={user}  api={api} graph={graph} type='business'/>
            </Route>
            <Route exact path="/challenges/business/new">
              <NewChallenge api={api} graph={graph} graphKey={graphKey} type='business'/>
            </Route>
            <Route exact path="/challenges/business/:id">
              <Challenge graphProfile={graphProfile} graph={graph} api={api}/>
            </Route>
            <Route exact path="/challenges/:id">
              <Challenge user={user} graphProfile={graphProfile} graph={graph} api={api}/>
            </Route>
            <Route exact path="/challenges/:id/edit">
              <NewChallenge graph={graph} api={api} update={true}/>
            </Route>
            <Route exact path="/projects">
              <Projects user={user} graph={graph} api={api} update={true}/>
            </Route>
            <Route exact path="/projects/new">
              <NewProject graphProfile={graphProfile} user={user} graph={graph} api={api} update={false}/>
            </Route>
            <Route exact path="/projects/:id">
              <Project user={user} graph={graph} api={api} update={true}/>
            </Route>
            <Route exact path="/projects/:id/edit">
              <NewProject user={user} graph={graph} api={api} update={true}/>
            </Route>
            <Route exact path="/">
              <Redirect to="/ideas"/>
            </Route>

          </Switch>
        )}

      </div>
    </div>

  );
}

export default AuthProvider(App);
