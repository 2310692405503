import React from 'react'
import { css } from 'glamor';

// eslint-disable-next-line react/prop-types
const Input = ({ inputStyle, style, placeholder, onChange, onBlur, error, type, value, onFocus,disabled, onKeyDown, endAdorn, startAdorn, autoFocus, min,max,step}) => {

  const inputDefaultStyle = {
    height: '36px',
    width: '340px',
    display: 'block',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: "#fff",
    lineHeight: '22px',
    padding: '8px',
    paddingRight: endAdorn ? '40px' : '8px',
    color: !disabled ? 'rgba(0, 0, 0, 0.8)' :'rgba(0, 0, 0, 0.2)' ,
    '::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
    ':focus': {
      border: '1px solid #DCEAF5',
    },
    boxShadow:'rgb(232, 232, 233) 15px 4px 150px',
    paddingLeft: startAdorn ? '40px' : '8px'
  };

  const errorStyles = {
    border: `2px solid red`,
  };

  const containerDefaultStyle = {
    display:'flex',
    alignSelf:'center',
    height:inputDefaultStyle.height
  }
  let cont = {...containerDefaultStyle, ...style}

  let s = { ...inputDefaultStyle, ...inputStyle };
  if (error) {
    s = { ...s, ...errorStyles };
  }

  return (
    <div style={cont}>
    <input
      min={min}
      max={max}
      step={step}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onFocus={onFocus}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...css(s)}
      placeholder={placeholder}
      type={type || 'text'}
    />
      {endAdorn && (
        <div style={{alignSelf:'center', position:'relative',marginLeft: `${- 35}px`, zIndex:'100000'}}>
          {endAdorn}
        </div>
      )}
      {startAdorn && (
        <div style={{alignSelf:'center', position:'absolute',marginLeft:'1px', zIndex:'100000'}}>
          {startAdorn}
        </div>
      )}

    </div>
  );
};

export default Input;
