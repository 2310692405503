import React from 'react';
import { css } from 'glamor';

// eslint-disable-next-line react/prop-types
const Select = ({
  multiple,
  styles,
  placeholder,
  onChange,
  onBlur,
  error,
  type,
  options,
  disabled,
  value
}) => {
  const defaultStyles = {
    height: '36px',
    width: '340px',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: '#fff',
    lineHeight: '22px',
    padding: '8px',
    color: 'rgba(0, 0, 0, 0.8)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .8)',
    },
  };

  const errorStyles = {
    border: `2px solid red`,
  };

  let s = { ...defaultStyles, ...styles };
  if (error) {
    s = { ...s, ...errorStyles };
  }

  return (
    <select
      multiple={multiple}
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...css(s)}
      placeholder={placeholder}
      type={type || 'text'}
    >
      {options}
    </select>
  );
};

export default Select;
