import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../../utils/api'
import { validate as uuidValidate } from 'uuid'
import getUserPicture from '../../utils/getUserPicture'
import capitalize from '../../utils/capitalize'
import MoonLoader from 'react-spinners/MoonLoader'
import rocketImg from '../../assets/undraw_To_the_stars_qhyy.svg'
import Button from '../../components/Button'
import ProjectCard from './ProjectCard'
import InfiniteScroll from 'react-infinite-scroll-component'

function Projects({type,graph, user}) {
  const {push} = useHistory();
  const location = useLocation();
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [page,setPage] = useState(0)


  const getPics = async (p) => {
    const projs = []
    console.log(p)
    //eslint-disable-next-line no-unused-vars
    for (let [i, project] of p.entries()) {
      if (project.stakeholders.length > 0) {
        const newStakes = []
        for (let stakeholder of project.stakeholders) {
          const valid = uuidValidate(stakeholder.adId)
          if (valid) {
            const pic = await getUserPicture({ id: stakeholder.adId }, api)
            stakeholder.pic = pic;
            newStakes.push(stakeholder)
          } else {
            newStakes.push(stakeholder)
          }
        }
        project.stakeholders = newStakes

      }

      project.lead.pic = await getUserPicture({ id: project.lead.adId }, api)
      projs.push(project)
    }
    return projs
  }

  useMemo(async () => {
      setLoading(true)
      try {
        let { data } = await api.get(type ? `/projects?type=${type}?page=${page}` : `/projects?page=${page}`)
        setLoading(false)
        const {results, total} = data
        const projsWithPics = await getPics(results)
        console.log(projsWithPics)
        setProjects(projects.concat(projsWithPics));
        if (projects.concat(projsWithPics).length < total) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setLoading(false)
        return results;
      } catch (e) {
        console.log(e)
      }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

  return (
    <>
    <>
      <>
        <div style={{
          marginLeft:'32px',
          marginTop:'52px',
          marginRight:'32px',
          alignItems:'center',
          display:'flex'
        }}>
          <h1 style={{ color:'#2465AF',marginTop:0}}>
            {capitalize(type)} Projects
          </h1>
          {user && user.type === 'internal' && projects.length > 0 && (
            <div style={{marginLeft:'auto', minWidth:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
              <div onClick={() => push(location.pathname + "/new")} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'100%',height:'100%',marginTop:'4px',color:'#2465AF'}}>+</div>
            </div>
          )}


        </div>


          {projects.length >= 1 && (
            <InfiniteScroll
              style={{
                overflow:'visible',
                height:'auto',
                width:'100%',
                display:'flex',
                flexWrap:'wrap'
              }}
              dataLength={projects.length} //This is important field to render the next data
              next={() => {
                setPage(page + 1);
              }}
              scrollableTarget="scrollable"
              hasMore={hasMore}
              loader={<></>}
            >
              {
                projects.map(project => (
                  <ProjectCard api={api} type={type} graph={graph} key={project._id} project={project}/>
                ))
              }

            </InfiniteScroll>
          )}
        {loading && (
          <>
            <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
          </>
        )}

          {!loading && projects.length <= 0 && (
            <>
              <div style={{display:'flex', flexDirection:'column',marginLeft:'auto',marginRight:'auto',alignItems:'center'}}>
                <img alt="Rocket" style={{width:'250px'}} src={rocketImg}/>
                <h2 style={{fontWeight:'400'}}>No {type} projects yet!</h2>
                {(user && user.type === 'internal') && (
                  <Button to={location.pathname + "/new"} title={`Post a project`}></Button>
                )}
              </div>

            </>
          )}
          </>

    </>
      </>

  );
}

export default Projects;
