import React  from 'react'
import {useHistory} from 'react-router-dom'
import capitalize from '../../utils/capitalize'
import SmallChip from '../../components/SmallChip'
import Chip from '@material-ui/core/Chip'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import CardFull from '../../components/CardFull'
import UserHorizontalScrollChipList from '../../components/UserHorizontalScrollChipList'
import { format } from 'timeago.js/esm'

function ProjectCard({project, type, user}) {

  const {push} = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <CardFull
      key={project.id}
      onClick={() => push(`/projects/${project._id}`)}
      coverImg={project.coverImage.link}
      >

        <div >
          <h1
            onMouseOver={(e) => e.stopPropagation() }
            style={{marginTop:'32px', fontSize:'20px'}}>{project.title}</h1>

          <div style={{display:'flex',flexWrap:'wrap'}}>
            {project.tags && project.tags.map(tag => (
              <p style={{margin:'0px', placeItems:'center', height:'18px', marginRight:'6px',marginTop:'6px', fontSize:'12px', paddingLeft:'6px',paddingRight:'6px', backgroundColor:'#416165',display:'flex',justifyContent:'center',borderRadius:'10px', color:'white'}}>{tag}</p>
            ))}
          </div>
          <div style={{display:'flex', marginBottom:'10px', flexWrap:'wrap'}}>
            <SmallChip style={{backgroundColor:'#00234c'}} value={capitalize(project.type)}/>
            <SmallChip style={{backgroundColor:'#a1bf37'}} value={`Start ${(new Date((project.start)).toLocaleString()).split(',')[0]}`}/>
            <SmallChip style={{backgroundColor:'#74b6ad'}} value={`End ${(new Date((project.end)).toLocaleString()).split(',')[0]}`}/>
          </div>
        </div>
        <h3 style={{fontSize:'15px',margin:'0'}}>Stakeholders</h3>
        <UserHorizontalScrollChipList
          users={project.stakeholders}
          extraStartChips={
          <Chip onClick={(e) => {
            e.stopPropagation()
            copy(project.lead.email)
            enqueueSnackbar(`Copied ${project.lead.email.toLowerCase()} to clipboard`)
          }} style={{marginLeft:'5px', color: 'white', backgroundColor:'rgb(36, 101, 175)'}} label={project.lead.name + ' 🏆'} avatar={
                  <img
                    alt="Logo"
                    src={project.lead.pic ? project.lead.pic : (`//logo.clearbit.com/${project.lead.email.split("@")[1]}?size=24`)}
                    onError={(e) => {
                      e.target.style.width = 0
                      e.target.style.border = 'none'
                    }}
                    style={{borderRadius:'100%', objectFit:'contain'}}/>
          }
        />}
          />

        <p onMouseOver={(e) => e.stopPropagation() }style={{marginRight:'32px',fontSize:'0.9em',overflow:'hidden'}}>{project.shortDescription}</p>
        <div onMouseOver={(e) => e.stopPropagation() } style={{marginTop:'auto',marginBottom:'10px', display:'flex'}}>
          <p style={{fontSize: '10px',marginTop:'auto'}}>{project.updated ? `Updated ${format(project.updated)}` : `Created ${format(project.created)} `}</p>
        </div>
      </CardFull>

    </>

  );
}

export default ProjectCard;
