import React, {useState, useEffect} from 'react';
import logo from '../assets/logo.png'
import personImg from '../assets/person_24px.png'
import contractImg from '../assets/file_copy_24px.png'
import businessImg from '../assets/business_center_24px.png'
import { faMeteor, faSatellite } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'
import kitemark from '../assets/mark-of-trust-kitemark-innovation-management-logo.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useLocation, useHistory, Link } from 'react-router-dom'
import Button from './Button'

function Sidebar({graphProfile, logout, isMobile }) {
  const [active,setActive] = useState(0);
  const location = useLocation();
  const {push} = useHistory();

  const getActiveChallengeType = () => {
    if (!location.pathname.includes("challenges")) return
    if (location.pathname.includes("challenges/client")) return 0
    if (location.pathname.includes("challenges/contract")) return 1
    if (location.pathname.includes("challenges/business")) return 2
    if (location.pathname.includes("projects")) return 3

  }
  useEffect(() => {
    if (location.pathname.includes("ideas")) setActive(0)
    if (location.pathname.includes("challenges")) setActive(1)
    if (location.pathname.includes("projects")) setActive(2)

  },[location])

  const sidebarItems = [
    {
      title: 'Ideas',
      icon: faLightbulb,
      to:'/ideas'
    },
    {
      title: 'Challenges',
      icon: faMeteor,
      to:'/challenges/client',
      sub: [
        {
          title: 'Client',
          icon: personImg,
          to:'/challenges/client',
        },
        {
          title: 'Contract',
          icon: contractImg,
          to:'/challenges/contract',
        },
        {
          title: 'Business',
          icon: businessImg,
          to:'/challenges/business',
        }
      ]
    },
    {
      title:'Projects',
      icon: faSatellite,
      to: '/projects'
    }
  ]
  
  return (
    <>
      {!isMobile && (
        <div style={{
          backgroundColor:'#fff',
          minWidth:'253px',
          width: '253px',
          height:'100vh',
          boxShadow: '15px 4px 250px #E8E8E9',
          display:'flex',
          flexDirection:'column',
          overflow:'scroll'
        }}>
          <Link to="/">
            <img src={logo} alt="logo" style={{height:'78px', width:'104px', margin:'32px'}}/>
          </Link>
          <div style={{marginTop: '100px', marginBottom:'50px'}}>
            {sidebarItems.map((item,index) => (
              <>
                <div
                  onClick={() => push(item.to)}
                  key={item.title}
                  style={{
                    height:'60px',
                    paddingLeft:'32px',
                    display:'flex',
                    color: active === index ? '#2465AF' : 'black',
                    boxShadow: active === index ?'inset 5px 0px 0px 0px #2465AF':null,
                    backgroundColor: '#fff',
                    cursor:'pointer'
                  }}
                >
                  <p
                    onMouseOver={(e) => e.stopPropagation()}
                    onMouseLeave={(e) => e.stopPropagation()}
                    style={{fontSize:'20px',display:'flex',alignSelf:'center'}}>
                    <FontAwesomeIcon icon={item.icon} style={{marginRight:'18px', width:'24px',height:'24px'}}/>
                     {item.title}</p>
                </div>

                {active === index && item.sub && (
                  <div style={{
                    boxShadow: active === index ?'inset 5px 0px 0px 0px #2465AF':null,
                    paddingBottom:'1px'
                  }}>
                    {active === index && item.sub && item.sub.map((subItem,y)=> (
                      <p style={{
                        fontSize:'16px',
                        marginTop:0,
                        marginBottom:0,
                        paddingBottom:'10px',
                        paddingTop:'10px',
                        paddingLeft:'50px',
                        display:'flex',
                        alignSelf:'center',
                        boxShadow: active === index ?'inset 5px 0px 0px 0px #2465AF':null,
                        color: getActiveChallengeType() === y ? '#2465AF' : 'black',
                        backgroundColor:  getActiveChallengeType() === y ? '#CDE1F2' : 'white',
                        cursor:'pointer'
                      }}
                         onClick={() => push(subItem.to)}
                      >{subItem.title}</p>
                    ))}
                  </div>
                )}

              </>
            ))}
          </div>
          <div style={{marginTop:'auto', width:'100%',marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column'}}>
            {
              (graphProfile && graphProfile.pic) && (
                <img alt="Profile" style={{backgroundColor:'white', border:'1px solid #DCEAF5', width:'48px', height:'48px',borderRadius:'100%',marginLeft:'auto',marginRight:'auto'}} src={graphProfile.pic}/>
              )
            }
            <p style={{textAlign:'center',marginTop:'5px'}}>{graphProfile && (graphProfile.displayName)}</p>
            <Button onClick={() => logout()} mouseOver={() => {}} mouseLeave={() => {}} textStyle={{color:'rgba(0, 0, 0, 0.5)'}} style={{borderRadius:'0',boxShadow:'none', width: '135px', background:'white',border:'1px solid #000000',backgroundColor:'white',marginBottom:'4px'}} title="Logout"/>
          </div>
          <Button onClick={() => window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=JJGwjMeyq02Jq7N4GqToCXleEx__MnRBrbRkIvheQs5URDdNMkFKTUlWSlBZQzJMT1owSTREMUdGSi4u")} mouseOver={() => {}} mouseLeave={() => {}} textStyle={{color:'rgba(0, 0, 0, 0.5)'}} style={{borderRadius:'0', marginTop:'4px',boxShadow:'none', width: '135px', background:'white',border:'1px solid #000000',backgroundColor:'white',marginBottom:'20px'}} title="Feedback"/>
         <a style={{marginLeft:'auto',marginRight:'auto'}} href="https://www.bsigroup.com/en-GB/kitemark/services/innovation-kitemark/">
           <img alt="Innovation Kitemark"  src={kitemark} style={{width: '100px'}}/>
         </a>
          <a style={{fontSize:'12pt',fontWeight:'200', marginBottom:'14px', color:'black', opacity:0.12,width:'100%', textAlign:'center',textDecoration:'none'}} href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      )}

    </>

  );
}

export default Sidebar;
