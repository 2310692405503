import React, { useEffect, useReducer, useState } from 'react'
import Button from '../../components/Button'
import editImg from '../../assets/baseline_create_black_18dp.png'
import {useHistory, useParams} from 'react-router-dom'
import getImpact from '../../utils/getImpact'
import getStatus from '../../utils/getStatus'
import capitalize from '../../utils/capitalize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getDocIcon from '../../utils/getDocIcon'
import { faThumbsUp, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { validate as uuidValidate } from 'uuid'
import getUserPicture from '../../utils/getUserPicture'
import MoonLoader from 'react-spinners/MoonLoader'
import SmallChip from '../../components/SmallChip'
import Modal from 'react-modal';
import getSectorIcon from '../../utils/getSectorIcon'
import muralLogo from '../../assets/muralLogo.png'
import Chip from '@material-ui/core/Chip'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'


function Idea({challengeTitle, graph,api, graphProfile, user}) {
  const {push} = useHistory();
  const {id} = useParams();

  const [idea,setIdea] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(()=>{
    let i;
    async function getIdea () {
      i = await api.get(`/ideas/${id}`)
      console.log(i)
      let owners = [];
        for (let owner of i.data.owners){
        const valid = uuidValidate(owner.adId)
        if (valid) {
          const pic = await getUserPicture({id:owner.adId},api)
          owner.pic = pic
        }
        owners.push(owner)
      }
      console.log(owners)
      i.data.owners = owners
      setIdea(i.data)
    }
    getIdea()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const likeIdea = async(ideaId) => {
    const x = await api.put(`/ideas/${ideaId}/like`)
    let newIdea = idea
        if (x.data.likes.includes(graphProfile.id)) {
          newIdea.likes = [...newIdea.likes,graphProfile.id]
        } else {
          newIdea.likes = newIdea.likes.filter(y => y !== graphProfile.id)
        }
    forceUpdate()
    setIdea(...[newIdea])
  }

  const deleteIdea = async (ideaId) => {
    setDeleting(true)
    await api.delete(`/ideas/${ideaId}`)
    setDeleting(false)
    push('/ideas')

  }
  const { enqueueSnackbar } = useSnackbar();

  const trlColors = ['#a7cad9','#92bdd0','#7cb0c6','#66a3bd','#5095b3','#3a88aa','#247ba0','#206f90','#1d6280']

  return (
    <>
      {!idea && (
        <>
          <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
        </>
      )}

      {idea && (
        <>
          <div style={{
            display:'flex',
            paddingLeft:'32px',
            marginTop:'52px',
            paddingRight:'32px',
          }}>
            <div style={{
              display:'flex',
              flexDirection:'column'
            }}>
              <h1 style={{ color:'#2465AF',margin:0}}>
                {challengeTitle}
              </h1>
              <div style={{display:'flex',marginBottom:'10px'}}>
                <h1 style={{ fontWeight:'700',margin:0, fontSize:'26px'}}>
                  {idea.title}
                </h1>
              </div>
              <div style={{display:'flex', marginBottom:'10px'}}>
                {(idea.partners && idea.partners.length > 0 && idea.partners[0].name) && (
                  <SmallChip value={idea.partners[0].name} img={
                    <img
                      alt="Partner Logo"
                      src={(`//logo.clearbit.com/${idea.partners[0].domain}?size=16`)}
                      onError={(e) => {
                        e.target.style.display = 'none'
                      }}
                      style={{
                        width:'16px',
                        height:'16px',
                        objectFit:'cover',
                        borderRadius:'100%',
                        marginLeft:'-6px',
                        marginRight:'3px',
                        border:'1px solid grey'
                      }}
                    />
                  }
                  />
                )}
                <SmallChip style={{backgroundColor:'green'}} value={getStatus(idea.status)}/>
                <SmallChip style={{backgroundColor:'#FBACBE'}} value={capitalize(idea.type)}/>
                { idea.impact && (
                  <SmallChip style={{backgroundColor:'#F7B05B'}} value={getImpact(idea.impact)}/>
                )}
                {idea.trl && (
                  <SmallChip style={{ backgroundColor:trlColors[idea.trl - 1]}} value={`TRL ${idea.trl}`}/>
                )}
                <span style={{display:'flex', alignItems: 'center'}}>
                {idea.sectors.map(sector => (
                  getSectorIcon(sector)
                ))}
              </span>
              </div>
              <h3 style={{fontSize:'15px',margin:'0',marginTop:'5px'}}>Owners</h3>
              <div style={{margin:0, marginTop: '5px',overflowX:'scroll',flexDirection:'row', flexWrap:'wrap', display:'flex',alignItems:"center", fontWeight:'400', fontSize:'20px', color:'rgba(0, 0, 0, 0.8)'}}>
                {idea.owners.length > 0 && (
                  <>
                    {idea.owners && idea.owners.map((owner) => (
                      <Chip onClick={(e) => {
                        e.stopPropagation()
                        copy(owner.email)
                        enqueueSnackbar(`Copied ${owner.email.toLowerCase()} to clipboard`)

                      }} style={{marginLeft:'5px', marginTop:'3px',marginBottom:'3px'}} label={owner.name} avatar={
                        <img
                          alt="Logo"
                          src={owner.pic ? owner.pic : (`//logo.clearbit.com/${owner.email.split("@")[1]}?size=24`)}
                          onError={(e) => {
                            e.target.style.display = 'none'
                          }}
                          style={{borderRadius:'100%', objectFit:'contain'}}/>} />
                    ))}
                  </>
                )}
              </div>
              {/*{idea.partners && (*/}
              {/*  <h3 style={{margin:0, display:'flex',alignItems:"center", fontWeight:'400', fontSize:'20px', color:'rgba(0, 0, 0, 0.8)'}}>*/}
              {/*    Partners: {idea.partners}*/}
              {/*  </h3>*/}
              {/*)}*/}


            </div>

            {((user && (user.admin === true || idea.owners.some(owner => owner._id === user._id)))) && (
              <div style={{marginLeft:'auto', width:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
                <div onClick={() => push(`/ideas/${id}/edit`)} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'64px',height:'64px',marginTop:'4px',color:'#2465AF'}}><img alt="Edit" style={{width:'24px',filter:'invert(29%) sepia(91%) saturate(1051%) hue-rotate(188deg) brightness(89%) contrast(86%)'}} src={editImg}/> </div>
              </div>
            )}

          </div>

          <div
            style={{
              paddingLeft:'32px',
              paddingRight:'32px',
              maxWidth:'800px'
            }}>
            <div style={{display:'flex',marginTop:'15px',flexDirection:'column'}}>
              {idea.supportingDocumentation && idea.supportingDocumentation.map((doc) => (
                <div style={{display:'flex',marginRight:'20px', alignItems:'center',marginTop:'3px'}}>
                  <a style={{color:'black', textDecoration:'none',display:'flex',alignItems:'center'}} href={doc.link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={getDocIcon(doc)}/>
                    <p style={{marginLeft:'5px',overflow:'hidden',marginBottom:'1px',marginTop:'1px'}}>{doc.name}</p>
                  </a>

                </div>
              ))}

            </div>
            <p dangerouslySetInnerHTML={{__html: idea.overview}}></p>

            <div style={{display:'flex', alignItems:'center'}}>
              {idea.tags && idea.tags.map(tag => (
                <p style={{margin:'0px', placeItems:'center', height:'18px', marginRight:'6px', fontSize:'12px', paddingLeft:'6px',paddingRight:'6px', backgroundColor:'#416165',display:'flex',justifyContent:'center',borderRadius:'10px', color:'white'}}>{tag}</p>
              ))}

              <div style={{display:'flex', marginLeft:'auto', marginRight: '0px'}}>

                {idea.muralLink && idea.muralLink.length > 0 && (
                  <Button icon={<img style={{width:'16px', height:'16px',marginRight:"8px"}} src={muralLogo} alt="Mural"/> } onClick={() => window.open(idea.muralLink, '_blank')} mouseOver={async () => {}} mouseLeave={async () => {}} style={{marginLeft:'auto',marginRight:'10px'}} title="Mural Board"/>
                )}
                <Button onClick={() => likeIdea(idea._id)} mouseLeave={(e) => {if(!(graphProfile && idea.likes.includes(graphProfile.id))) e.target.style.background = 'none'}} mouseOver={(e) => e.target.style.background = '#E8E8E9'} style={{marginRight:'5px',background: (graphProfile && idea.likes.includes(graphProfile.id)) ? '#E8E8E9':'none', border:'1px solid #E8E8E9', boxShadow: '0px 2px 0px #E8E8E9'}} title="" icon={<>{(graphProfile && idea.likes.length > 0) && (
                  <p style={{marginRight:'8px',marginTop:'0px',marginBottom:'0px'}}>{idea.likes.length}</p>
                )}<FontAwesomeIcon color="rgba(101, 97, 125, 0.8)" icon={faThumbsUp}/></>} />
                {((user && (user.admin === true  || idea.owners.some(owner => owner._id === user._id)))) && (
                  <Button onClick={() => setDeleteModal(true)} mouseLeave={(e) => {e.target.style.background = 'none'}} mouseOver={(e) => e.target.style.background = '#E8E8E9'} style={{marginRight: '0px',background:'none', border:'1px solid #E8E8E9', boxShadow: '0px 2px 0px #E8E8E9'}} title="" icon={<><FontAwesomeIcon color="rgba(101, 97, 125, 0.8)" icon={faTrash}/></>} />
                )}

              </div>

            </div>
            <div style={{width:'100%',height:'1px', backgroundColor:'rgba(0,0,0,0.2)',marginTop:'5px'}}></div>

          </div>
          <Modal
            isOpen={deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={{overlay: {
                zIndex:'99999999',
                backgroundColor: 'rgba(0, 0, 0, 0.4)'
              },
            content: {
              width:'300px',
              height:'200px',
              marginLeft:'auto',
              marginRight:'auto',
              padding:'30px'
            } }}
            contentLabel="Delete Idea"
          >
            <div style={{width:'100%'}}>
              <FontAwesomeIcon style={{width:'32px',height:'32px',display:'flex',marginLeft:'auto',marginRight:'auto', color:'orangered'}} icon={faExclamationTriangle}/>
            </div>
           <h2 style={{textAlign:'center', fontWeight:'600'}}>Are you sure you want to delete this idea?</h2>
            <div style={{display:'flex'}}>
              <Button  onClick={() => setDeleteModal(false)} title="Cancel"/>
              <Button loading={deleting} textStyle={{color:'white'}} style={{backgroundColor:'orangered', boxShadow: '0px 2px 0px #a50000', border:'none'}} onClick={() => deleteIdea(idea._id)} title="Yes, delete"/>
            </div>
          </Modal>
        </>
      )}

    </>

  );
}

export default Idea;
