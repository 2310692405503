import React, { useState } from 'react'

function CardFull({
  key, onClick, style,coverImg, children
}) {

  const [hover, setHover] = useState(false);
  const [coverOpen, setCoverOpen] = useState(false)

  const defaultStyle = {
    width:'483px',
    minHeight:'400px',
    maxHeight: coverOpen ? '400px': 'none',
    backgroundColor:'white',
    margin:'20px',
    boxShadow: hover ? '15px 4px 150px #E8E8E9' :'15px 4px 250px #E8E8E9',
    cursor:'pointer',
    display:'flex',
    flexDirection:'column',
    minWidth:'0px'
  }

  const s = {...defaultStyle, style}

  return (
      <div
        style={s}
        key={key}
        onMouseOver={(e) => {
          setHover(true)
        }}
        onMouseLeave={(e) => {
          setHover(false)
        }}
        onClick={onClick}
      >
        {coverImg && (
          <>
          <img alt="cover" onClick={(e) => {
            e.stopPropagation()
            setCoverOpen(!coverOpen)}
          }  style={{width:'100%', height: coverOpen? '100%': '50px', objectFit:'cover',
            clipPath: coverOpen ? 'none': 'polygon(100% 0, 0 0, 100% 70%)',
          }} src={coverImg}/>
          </>
        )}
        {!coverOpen && (
          <div style={{
            marginTop: coverImg ? '-50px' :'0px',
            paddingLeft:'32px',
            paddingRight:'32px',
            display:'flex',
            flexDirection:'column',
            height:'100%'
          }}>
            {children}
          </div>
        ) }

      </div>
  );
}

export default CardFull;