import React from 'react';
import RichTextEditor from 'react-rte';

function RichInput({ setter, value, placeholder, remove}) {

  return (
    <div style={{borderRadius:'5px',marginBottom:'10px', maxWidth:'860px'}}>
      <RichTextEditor
        editorClassName="rich-text-editor"
        value={value}
        onChange={ ( value ) => {
          setter(value)
        } }
      />
    </div>
    )

}

export default RichInput