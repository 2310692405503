import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import capitalize from '../../utils/capitalize'
import lightbulbImg from '../../assets/lightbulb-solid.svg'
import getStatus from '../../utils/getStatus'
import getImpact from '../../utils/getImpact'
import { format } from 'timeago.js/esm'
import trlColors from '../../utils/trlColors'
import { validate as uuidValidate } from 'uuid'
import getUser from '../../utils/getUser'
import SmallChip from '../../components/SmallChip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import getSectorIcon from '../../utils/getSectorIcon'
import CardSlim from '../../components/CardSlim'

function IdeaCard({idea,api,graphProfile, graph, setIdeas, ideas, like, action, actionOnClick}) {

  const {push} = useHistory();
  const [createdBy, setCreatedBy] = useState('')

  const likeIdea = async(ideaId) => {
    const x = await api.put(`/ideas/${ideaId}/like`)
    const i = ideas.map(idea => {
      if (idea._id === ideaId) {
        if (x.data.likes.includes(graphProfile.id)) {
          idea.likes = [...idea.likes,graphProfile.id]
        } else {
          idea.likes = idea.likes.filter(y => y !== graphProfile.id)

        }
      }
      return idea
    })
    setIdeas(...[i])
  }

  useEffect(() => {
    const getCreatedBy = async() => {
      if (uuidValidate(idea.createdBy)) {
        let user = await getUser(graph,idea.createdBy)
        setCreatedBy(user.displayName)
      } else {
        setCreatedBy(idea.createdBy)
      }
    }
    getCreatedBy()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[idea])

  return (
    <>
      {idea && (
        <CardSlim
          key={idea._id}
          onClick={() => { !action ? push(`/ideas/${idea._id}`): window.open(`/ideas/${idea._id}`, "_blank")}}
        >
          {((idea.partners && idea.partners.length > 0) || (idea.coverImage && idea.coverImage.link)) && (
            <div style={{height:'100px',minWidth:'100px', width:'100px', display:'flex',justifyContent:'center', alignItems:'center'}}>
              <>
                {(idea.partners && idea.partners.length > 0 && idea.partners[0].domain && !idea.coverImage) && (
                  <img
                    alt=""
                    src={(idea.coverImage && idea.coverImage.link)  ? idea.coverImage.link : `//logo.clearbit.com/${idea.partners[0].domain}?size=70`}
                    onError={(e)=>{e.target.onerror = null; e.target.parentNode.style.display='none'}}
                    style={{
                      width:'70px',
                      maxHeight:'100px',
                      objectFit: 'cover',
                    }}
                  />
                )}
                {(idea.coverImage) && (
                  <img
                    alt=""
                    src={(idea.coverImage && idea.coverImage.link)  ? idea.coverImage.link : lightbulbImg}
                    style={{
                      width:'100%',
                      height:'100%',
                      objectFit: (idea.coverImage && idea.coverImage.link) ? 'cover' : 'fit',
                    }}
                  />
                )}
              </>
            </div>
          )}

          <div style={{paddingLeft:'10px',width:'100%',boxShadow:'-8px 0px 16px 0px rgba(0,0,0,0.08)',marginRight:'10PX'}}>
            <h1 style={{fontSize:'18px', marginBottom:'0',fontWeight:'500', marginRight:'5px'}}>
              {idea.title}
            </h1>
            <div style={{display:'flex', marginTop:'5px', flexWrap:'wrap'}}>

              {(idea.partners && idea.partners.length > 0 && idea.partners[0].name) && (
                <SmallChip value={idea.partners[0].name} img={
                  <img
                    alt="Partner Logo"
                    src={(`//logo.clearbit.com/${idea.partners[0].domain}?size=16`)}
                    onError={(e) => {
                      e.target.style.display = 'none'
                    }}
                    style={{
                      width:'16px',
                      height:'16px',
                      objectFit:'cover',
                      borderRadius:'100%',
                      marginLeft:'-6px',
                      marginRight:'3px',
                      border:'1px solid grey',
                      alignSelf:'center'
                    }}
                  />
                }
                />
              )}
              <SmallChip style={{backgroundColor:'green'}} value={getStatus(idea.status)}/>
              <SmallChip style={{backgroundColor:'#FBACBE'}} value={capitalize(idea.type)}/>

              { idea.impact && (
                <SmallChip style={{backgroundColor:'#F7B05B'}} value={getImpact(idea.impact)}/>
              )}
              {idea.trl && (
                <SmallChip style={{ backgroundColor:trlColors[idea.trl - 1]}} value={`TRL ${idea.trl}`}/>
              )}
              {idea.sectors && (
                <span style={{display:'flex', alignItems: 'center'}}>
                {idea.sectors.map(sector => (
                  getSectorIcon(sector)
                ))}
              </span>
              )}
            </div>
            <div style={{display:'flex'}}>
              {createdBy && createdBy.name && (<p style={{fontSize:'10px'}}>Posted {format(idea.created)} by {createdBy.name}</p>)}
              <div style={{display:'flex',alignSelf:'center',marginLeft:'auto',marginRight:'20px'}}>
                {idea.tags && idea.tags.slice(0,1).map(tag => (
                  <>
                    {(tag !== 'archive' && tag !== 'internal' && tag !== 'external') && (
                      <SmallChip style={{opacity: '0.3', color:'black'}} value={tag}/>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
          {like && (
            <>
              <div
                onClick={(e) =>  {
                  e.stopPropagation()
                  likeIdea(idea._id)
                }}
                style={{
                  display:'flex',
                  flex:'1',
                  backgroundColor: (graphProfile && idea.likes.includes(graphProfile.id)) ? '#2465AF':'#f3f3f3',
                  paddingLeft:'20px'
                }}>
                <div style={{
                  display:'flex',
                  marginRight:'20px',
                  alignSelf:'center',
                  flexDirection:'column'
                }}>
                  <FontAwesomeIcon color={ (graphProfile && idea.likes.includes(graphProfile.id)) ? 'white':'black'}  style={{width:'20px',marginTop:'8px', cursor:'pointer'}} icon={faThumbsUp}/>
                  <span style={{color: (graphProfile && idea.likes.includes(graphProfile.id)) ? 'white':'black', marginRight:'5px', alignSelf:'center',fontSize:'11px',marginTop:'5px'}}>{idea.likes.length}</span>
                </div>
              </div>
            </>
          )}

          {action && (
            <>
              <div
                onClick={(e) =>  {
                  e.stopPropagation()
                  actionOnClick()
                }}
                style={{
                  display:'flex',
                  flex:'1',
                  backgroundColor: (graphProfile && idea.likes.includes(graphProfile.id)) ? '#2465AF':'#f3f3f3',
                  paddingLeft:'20px'
                }}>
                <div style={{
                  display:'flex',
                  marginRight:'20px',
                  alignSelf:'center',
                  flexDirection:'column'
                }}>
                  {action}
                </div>
              </div>
            </>
          )}

        </CardSlim>



      )}

    </>

  );
}

export default IdeaCard;
