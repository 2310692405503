import React, { useEffect, useReducer, useState } from 'react'
import Button from '../../components/Button'
import editImg from '../../assets/baseline_create_black_18dp.png'
import {useHistory, useParams} from 'react-router-dom'
import capitalize from '../../utils/capitalize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getDocIcon from '../../utils/getDocIcon'
import { validate as uuidValidate } from 'uuid'
import getUserPicture from '../../utils/getUserPicture'
import MoonLoader from 'react-spinners/MoonLoader'
import SmallChip from '../../components/SmallChip'
import Chip from '@material-ui/core/Chip'
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack'
import muralLogo from '../../assets/muralLogo.png'
import IdeaCard from '../Ideas/IdeaCard'

function Project({challengeTitle, graph,api, graphProfile, user}) {
  const {push} = useHistory();
  const {id} = useParams();

  const [project, setProject] = useState()
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const completeObjective = async (obj) => {
    const x = await api.put(`/projects/${id}/objective/${obj._id}`)
    const proj = project;
    proj.objectives = x.data.objectives
    console.log(proj)
    setProject(proj)
    forceUpdate()
  }

  useEffect(()=>{
    let i;
    async function getProject () {
      i = await api.get(`/projects/${id}`)
      console.log(i)
      if (i.data && i.data.stakeholders.length > 0) {
        console.log(i.data.stakeholders)
        let stakeholders = [];
        for (let stakeholder of i.data.stakeholders) {
          const valid = uuidValidate(stakeholder.adId)
          if (valid) {
            const pic = await getUserPicture({ id: stakeholder.adId }, api)
            stakeholder.pic = pic
          }
          stakeholders.push(stakeholder)
        }
        i.data.stakeholders = stakeholders
      }
      if (i.data) {
        const pic = await getUserPicture({id: i.data.lead.adId}, api)
        i.data.lead.pic = pic;
      }
      setProject(i.data)

    }
    getProject()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {!project && (
        <>
          <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
        </>
      )}

      {project && (
        <>
          <div style={{
            display:'flex',
            paddingLeft:'32px',
            marginTop:'52px',
            paddingRight:'32px',
          }}>
            <div style={{
              display:'flex',
              flexDirection:'column',
              maxWidth:'800px'
            }}>

              <h1 style={{ color:'#2465AF',margin:0}}>
                {challengeTitle}
              </h1>
              <div style={{display:'flex',marginBottom:'10px'}}>
                <h1 style={{ fontWeight:'700',margin:0, fontSize:'26px'}}>
                  {project.title}
                </h1>

              </div>
              <div style={{display:'flex',flexFlow:'wrap'}}>
                {project.tags && project.tags.map(tag => (
                  <p style={{margin:'0px', marginTop:'6px',placeItems:'center', height:'18px', marginRight:'6px', fontSize:'12px', paddingLeft:'6px',paddingRight:'6px', backgroundColor:'#416165',display:'flex',justifyContent:'center',borderRadius:'10px', color:'white'}}>{tag}</p>
                ))}
              </div>
              <div style={{display:'flex', marginBottom:'10px', flexFlow:'wrap'}}>
                <SmallChip style={{backgroundColor:'#00234c'}} value={capitalize(project.type)}/>
                <SmallChip style={{backgroundColor:'#a1bf37'}} value={`Start ${(new Date((project.start)).toLocaleString()).split(',')[0]}`}/>
                <SmallChip style={{backgroundColor:'#74b6ad'}} value={`End ${(new Date((project.end)).toLocaleString()).split(',')[0]}`}/>
              </div>
              <h3 style={{fontSize:'15px',margin:'0'}}>Stakeholders</h3>
              <div style={{margin:0, marginTop: '5px',overflowX:'scroll',flexDirection:'row', flexWrap:'wrap', display:'flex',alignItems:"center", fontWeight:'400', fontSize:'20px', color:'rgba(0, 0, 0, 0.8)'}}>
                <Chip onClick={(e) => {
                  e.stopPropagation()
                  copy(project.lead.email)
                  enqueueSnackbar(`Copied ${project.lead.email} to clipboard`)
                }} style={{marginLeft:'5px', color: 'white', backgroundColor:'rgb(36, 101, 175)'}} label={project.lead.name + ' 🏆'} avatar={
                  <img
                    alt="Logo"
                    src={project.lead.pic ? project.lead.pic : (`//logo.clearbit.com/${project.lead.email.split("@")[1]}?size=24`)}
                    onError={(e) => {
                      e.target.style.width = 0
                      e.target.style.border = 'none'
                    }}
                    style={{borderRadius:'100%', objectFit:'contain'}}/>
                } />
                {project.stakeholders.length > 0 && (
                  <>
                    {project.stakeholders && project.stakeholders.map((stakeholder) => (
                      <Chip onClick={(e) => {
                        e.stopPropagation()
                        copy(stakeholder.email)
                        enqueueSnackbar(`Copied ${stakeholder.email} to clipboard`)

                      }} style={{marginLeft:'5px', marginTop:'3px',marginBottom:'3px'}} label={stakeholder.name} avatar={
                        <img
                          alt="Logo"
                          src={stakeholder.pic ? stakeholder.pic : (`//logo.clearbit.com/${stakeholder.email.split("@")[1]}?size=24`)}
                          onError={(e) => {
                            e.target.style.width = 0
                            e.target.style.border = 'none'
                          }}
                          style={{borderRadius:'100%', objectFit:'contain'}}/>} />
                    ))}
                  </>
                )}
              </div>


              {/*{project.partners && (*/}
              {/*  <h3 style={{margin:0, display:'flex',alignItems:"center", fontWeight:'400', fontSize:'20px', color:'rgba(0, 0, 0, 0.8)'}}>*/}
              {/*    Partners: {project.partners}*/}
              {/*  </h3>*/}
              {/*)}*/}


            </div>
            {((user && (user.admin === true || (project.stakeholders.some(stakeholder => stakeholder._id === user._id)|| project.lead._id === user._id)))) && (
              <div style={{marginLeft:'auto', minWidth:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
                <div onClick={() => push(`/projects/${id}/edit`)} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'100%',height:'100%',marginTop:'4px',color:'#2465AF'}}><img alt="Edit" style={{width:'24px',filter:'invert(29%) sepia(91%) saturate(1051%) hue-rotate(188deg) brightness(89%) contrast(86%)'}} src={editImg}/> </div>
              </div>
            )}
          </div>

          <div
            style={{
              paddingLeft:'32px',
              paddingRight:'32px',
              paddingBottom:'50px'
            }}>
            <div style={{display:'flex',marginTop:'15px',flexDirection:'column'}}>
              {project.supportingDocumentation && project.supportingDocumentation.map((doc) => (
                <div style={{display:'flex',marginRight:'20px', alignItems:'center',marginTop:'3px'}}>
                  <a style={{color:'black', textDecoration:'none',display:'flex',alignItems:'center'}} href={doc.link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={getDocIcon(doc)}/>
                    <p style={{marginLeft:'5px',overflow:'hidden',marginBottom:'1px',marginTop:'1px'}}>{doc.name}</p>
                  </a>

                </div>
              ))}

            </div>

            <div style={{display:'flex', alignItems:'center'}}>
              <span style={{marginRight:'4px'}}></span>

              <div style={{display:'flex', marginLeft:project.muralLink ? '0px' : 'auto', marginRight: '0px'}}>
                {project.muralLink && project.muralLink.length > 0 && (
                  <Button icon={<img style={{width:'16px', height:'16px',marginRight:"8px"}} src={muralLogo} alt="Mural"/> } onClick={() => window.open(project.muralLink, '_blank')} mouseOver={async () => {}} mouseLeave={async () => {}} style={{marginLeft:'auto',marginRight:'10px'}} title="Mural Board"/>
                )}
              </div>

            </div>
            <div  style={{maxWidth:'800px'}}>
              <p>{project.shortDescription}</p>
              <h3>Aims</h3>
              <ul>
                {project.aims.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
              <h3>Objectives</h3>
              {project.objectives.length > 0 && (
                <ul style={{marginTop:'2px'}}>
                  {project.objectives.map((objective) => (
                    <div style={{display:'flex', alignItems:'center',marginTop:'10px'}}>
                      <input
                        checked={objective.completed != null}
                        onChange={async () => {
                            await completeObjective(objective)
                        }}
                        disabled={
                        !((user && (user.admin === true || project.lead === user._id)))
                      }  type="checkbox"/>
                      <div style={{display:'flex', flexDirection:'column'}}>
                        <h3 style={{margin:0,marginLeft:'10px'}}>{objective.name}</h3>

                        <p style={{margin:0, marginLeft: '10px', fontSize:'12px'}}> {objective.completed && (
                          <>
                            Completion Date: {new Date(objective.completed).toLocaleDateString()}
                          </>
                        )} </p>

                        <p style={{margin:0, marginLeft: '10px', fontSize:'12px'}}> {(objective.completed == null && objective.due) && (
                          <>
                            Due Date: {new Date(objective.due).toLocaleDateString()}
                          </>
                        )} </p>
                      </div>
                    </div>
                  ))}
                </ul>
              )}
              {project.relatedIdeas.length > 0 && (
                <>
                  <h3>Related Ideas</h3>
                  {project.relatedIdeas.map((idea) => (
                    <IdeaCard idea={idea} graph={graph} api={api}/>
                  ))}
                </>
              )}

            </div>

          </div>

        </>
      )}

    </>

  );
}

export default Project;
