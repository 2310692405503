import {
  faFile,
  faFileArchive, faFileAudio,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint, faFileVideo,
  faFileWord
} from '@fortawesome/free-solid-svg-icons'

const getDocIcon = (doc) => {
  if (doc.type === 'application/msword' || doc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return faFileWord
  if (doc.type === 'application/vnd.ms-excel' || doc.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return faFileExcel
  if (doc.type === 'application/vnd.ms-powerpoint' || doc.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return faFilePowerpoint
  if (doc.type === 'application/gzip' || doc.type === 'application/vnd.rar' || doc.type === 'application/zip'  || doc.type === 'application/x-7z-compressed') return faFileArchive
  if (doc.type === 'application/pdf') return faFilePdf
  if (doc.type.includes('image')) return faFileImage
  if (doc.type.includes('audio')) return faFileAudio
  if (doc.type.includes('video')) return faFileVideo
  return faFile
}

export default getDocIcon