import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import capitalize from '../../utils/capitalize'
import {faUserCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmallChip from '../../components/SmallChip'
import getChallengeTypeColor from '../../utils/getChallengeTypeColor'
import getSectorIcon from '../../utils/getSectorIcon'
import CardFull from '../../components/CardFull'
import UserHorizontalScrollChipList from '../../components/UserHorizontalScrollChipList'
import { Tooltip } from '@material-ui/core'


function ChallengeCard({challenge, type, user}) {

  const {push} = useHistory();
  const [postedBy, setPostedBy] = useState([])

  useEffect(() => {
    const pb =[]
    const ids = []
    challenge.ideas.forEach((idea) => {
      if (!ids.includes(idea.id) && pb.length < 3) {
        pb.push(idea)
        ids.push(idea.id)
      }
    })
    setPostedBy(pb)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <CardFull
      key={challenge.id}
      onClick={() => push(`/challenges/${type}/${challenge._id}`)}
      >
        <h1
          onMouseOver={(e) => e.stopPropagation() }
          style={{marginTop:'32px', fontSize:'20px'}}>{challenge.title}</h1>

        <div onMouseOver={(e) => e.stopPropagation() } style={{display:'flex'}}>
          <SmallChip style={{backgroundColor:getChallengeTypeColor(challenge.type), marginTop:'0'}} value={capitalize(challenge.type)}/>
          {(challenge.sectors && challenge.sectors.length > 0) && (
            <span style={{display:'flex', alignItems: 'center'}}>
                {challenge.sectors.map(sector => (
                  getSectorIcon(sector,'top')
                ))}
              </span>
          )}
        </div>
        {challenge.client && (
          <div style={{display:'flex'}}>
            <SmallChip value={challenge.client}></SmallChip>
          </div>
        )}
        <h2 onMouseOver={(e) => e.stopPropagation() } style={{color:'rgba(0,0,0,0.8)', fontWeight:'400',fontSize:'12px',lineHeight:'14px',margin:0,marginTop:'5px'}}>{challenge.value ? `Value ${new Intl.NumberFormat('en-GB',{style:'currency',currency: 'GBP'}).format(challenge.value.amount)}` : ''}</h2>
        <h3 style={{fontSize:'15px',margin:'0', marginTop:'5px'}}>Stakeholders</h3>
        <UserHorizontalScrollChipList users={challenge.stakeholders}/>
        <div onMouseOver={(e) => e.stopPropagation() }style={{marginRight:'32px', fontSize:'0.9em',height:'100%', maxHeight:'135px',overflow:'hidden'}} dangerouslySetInnerHTML={{__html: challenge.description}}/>
        <div onMouseOver={(e) => e.stopPropagation() } style={{marginTop:'20px',lineHeight:'24px', marginBottom:'20px', display:'flex'}}>
          <h2 style={{fontSize:'20px',marginTop:'10px',color:'#2465AF',margin:0, fontWeight:'400'}}>{challenge.ideas.length} {challenge.ideas.length === 1 && ('Idea')} {(challenge.ideas.length > 1 || challenge.ideas.length ===0)  && ('Ideas')}</h2>
          {challenge.ideas.length > 0 && (
            <h2 style={{fontSize:'20px',lineHeight:'24px', marginRight:'0',color:'rgba(0,0,0,0.8)',margin:0, fontWeight:'400',marginLeft:'auto',display:'flex',alignItems:'center'}}>
              <span style={{marginRight:'4px', fontSize:'12px'}}>Posted by</span>
              {
                (postedBy.length> 0 && postedBy.map((idea) => {
                  return (
                    <Tooltip title={idea.createdBy.name}>
                      {
                        (idea.createdBy.pic && idea.createdBy.pic !== null) ?
                          <img alt="" key={idea.id} src={idea.createdBy.pic} style={{width:'20px', height:'20px',borderRadius:'100%',marginLeft:'3px',marginRight:'3px'}}/> :
                          <FontAwesomeIcon key={idea.id} icon={faUserCircle} style={{width:'20px', height:'20px',borderRadius:'100%',marginLeft:'3px',marginRight:'3px'}} />
                      }
                    </Tooltip>
                  )
                }
              ))}
            </h2>
            )}
        </div>
      </CardFull>

    </>

  );
}

export default ChallengeCard;
