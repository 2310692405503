import React, { Component } from 'react'
import {
  fetchMsGraph,
  fetchMsGraphPhoto,
  GRAPH_ENDPOINTS,
  GRAPH_REQUESTS,
  GRAPH_SCOPES,
  msalApp,
  requiresInteraction
} from './utils/auth'
import { WindowUtils } from 'msal'
import api from './utils/api'
import graph from './utils/graph'

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = true;
// const useRedirectFlow = true;

export default C =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
        apiKey: null,
        graphKey: null,
        user: null,
        loggingIn: false,
        admins: []
      };

      this.onSignIn = this.onSignIn.bind(this)

      api.interceptors.response.use(async (res) => {
        //console.log(res)
        return res
      }, async (err) => {
        if (err.response && err.response.status === 401 && !this.state.loggingIn) {
          this.onSignIn({})
        } else {
          throw err
        }
      })

      graph.interceptors.response.use(async (res) => {
        return res
      }, async (err) => {
        if(err.response && err.response.status === 401 && !this.state.loggingIn) {
          this.onSignIn({})
        } else {
          throw err
        }
      })

    }

    async acquireToken(request, redirect,email, sid) {
      this.setState({loggingIn: true})
      console.log("The email is: " + email)
      console.log("The sid is: " + sid)

      return msalApp.acquireTokenSilent({...request, loginHint:email, sid}).catch(error => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
          msalApp.acquireTokenRedirect({
              ...request,
              redirectUri: process.env.REACT_APP_DEPLOY_URL,
              loginHint: email,
              sid
            })
        } else {
          console.error('Non-interactive error:', error)
        }
      });
    }
    async onSignIn({redirect,email}) {
      this.setState({loggingIn: true})
      localStorage.setItem("redirect", redirect)
      console.log("REDIRECT: " + redirect)
      console.log("EMAIL: " + email)
        const acc = await msalApp.getAccount();
        if (!acc) {
          console.log("no account so redirect to login")
          return msalApp.loginRedirect({
            ...GRAPH_REQUESTS.LOGIN,
            redirectUri: `${process.env.REACT_APP_DEPLOY_URL}`,
            loginHint: email,
          });
        } else {
            const tokenResponse = await this.acquireToken(
              GRAPH_REQUESTS.LOGIN,
              redirect,
               email,
              acc.sid
            ).catch(error => {
              this.setState({
                error: error.message
              });
            });
            if (tokenResponse) {
              this.setState({loggingIn: false})
              console.log(tokenResponse.accessToken)
              graph.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse.accessToken}`
              graph.defaults.headers.common['ConsistencyLevel'] = `eventual`
              console.log(tokenResponse.accessToken)
              this.setState({graphKey:tokenResponse.accessToken})
              let graphProfile
              try {
                graphProfile = await fetchMsGraph(GRAPH_ENDPOINTS.ME, tokenResponse.accessToken)
              } catch (e) {
                console.error(e)
              }

              let graphPhoto;
              try {
                graphPhoto = await fetchMsGraphPhoto(GRAPH_ENDPOINTS.ME + '/photo/$value', tokenResponse.accessToken)
                console.log(graphPhoto)
                graphProfile.pic = URL.createObjectURL(graphPhoto)
              } catch (e) {
                console.error(e)
              }
              this.setState({
                graphProfile
              });
            try {
              const apiTokenResponse = await this.acquireToken(GRAPH_REQUESTS.API)
              api.defaults.headers.common['Authorization'] = `Bearer ${apiTokenResponse.accessToken}`
              this.setState({apiKey: apiTokenResponse.accessToken})
              const user = await api.get(`/user/${apiTokenResponse.account.accountIdentifier}`)
              this.setState({user})
            } catch (e) {
              console.log(e)
            }
            }
        }


      // const loginResponse = await msalApp
      //   .loginPopup(GRAPH_REQUESTS.LOGIN)
      //   .catch(error => {
      //     this.setState({
      //       error: error.message
      //     });
      //   });
      //
      // if (loginResponse) {
      //   this.setState({
      //     account: loginResponse.account,
      //     error: null
      //   });
      //
      //   const tokenResponse = await this.acquireToken(
      //     GRAPH_REQUESTS.LOGIN
      //   ).catch(error => {
      //     this.setState({
      //       error: error.message
      //     });
      //   });
      //
      //   if (tokenResponse) {
      //     const graphProfile = await fetchMsGraph(
      //       GRAPH_ENDPOINTS.ME,
      //       tokenResponse.accessToken
      //     ).catch(() => {
      //       this.setState({
      //         error: "Unable to fetch Graph profile."
      //       });
      //     });
      //
      //     if (graphProfile) {
      //       this.setState({
      //         graphProfile
      //       });
      //     }
      //
      //     if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
      //       return this.readMail(tokenResponse.accessToken);
      //     }
      //   }
      }


    onSignOut() {
      msalApp.logout();
    }

    async readMail(accessToken) {
      const emailMessages = await fetchMsGraph(
        GRAPH_ENDPOINTS.MAIL,
        accessToken
      ).catch(() => {
        this.setState({
          error: "Unable to fetch email messages."
        });
      });

      if (emailMessages) {
        this.setState({
          emailMessages,
          error: null
        });
      }
    }

    async componentDidMount() {
      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage
          });
        }
      });

      const account = msalApp.getAccount();
      this.setState({
        account
      });
      if (account && !WindowUtils.isInIframe()) {
        const tokenResponse = await this.acquireToken(
          GRAPH_REQUESTS.LOGIN,
          useRedirectFlow,
        );

        const apiTokenResponse = await this.acquireToken(
          GRAPH_REQUESTS.API,
          useRedirectFlow,
        );

        if (apiTokenResponse) {
          this.setState({loggingIn: false})
          api.defaults.headers.common['Authorization'] = `Bearer ${apiTokenResponse.accessToken}`
          this.setState({apiKey: apiTokenResponse.accessToken})
          try {
            const {data} = await api.get(`/user/${apiTokenResponse.account.accountIdentifier}`)
            this.setState({user: data})
          } catch (e) {
            console.log(e)
          }
        }


        if (tokenResponse) {
          graph.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse.accessToken}`
          graph.defaults.headers.common['ConsistencyLevel'] = `eventual`
          const graphProfile = await fetchMsGraph(
            GRAPH_ENDPOINTS.ME,
            tokenResponse.accessToken
          ).catch(() => {
            this.setState({
              error: "Unable to fetch Graph profile."
            });
          });
          let graphPhoto;
          try {
            graphPhoto = await fetchMsGraphPhoto(GRAPH_ENDPOINTS.ME + '/photo/$value', tokenResponse.accessToken)
            graphProfile.pic = URL.createObjectURL(graphPhoto)
          } catch (e) {
            console.error(e)
          }
          this.setState({
            graphProfile
          });

          if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
            return this.readMail(tokenResponse.accessToken);
          }
        }
      }
    }

    render() {
      return (
        <C
          {...this.props}
          account={this.state.account}
          emailMessages={this.state.emailMessages}
          error={this.state.error}
          graphProfile={this.state.graphProfile}
          user={this.state.user}
          onSignIn={this.onSignIn}
          onSignOut={() => this.onSignOut()}
          apiKey={this.state.apiKey}
          graphKey={this.state.graphKey}
          api={api}
          graph={graph}
          loggingIn={this.state.loggingIn}
        />
      );
    }
  };