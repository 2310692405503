import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import editImg from '../../assets/baseline_create_black_18dp.png'
import { useHistory, useParams } from 'react-router-dom'
import capitalize from '../../utils/capitalize'
import { validate as uuidValidate } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getDocIcon from '../../utils/getDocIcon'
import MoonLoader from "react-spinners/MoonLoader";
import newIdeas from '../../assets/undraw_new_ideas_jdea.svg'
import IdeaCard from '../Ideas/IdeaCard'
import getUserPicture from '../../utils/getUserPicture'
import Chip from '@material-ui/core/Chip'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import SmallChip from '../../components/SmallChip'
import getSectorIcon from '../../utils/getSectorIcon'
import getChallengeTypeColor from '../../utils/getChallengeTypeColor'
import muralLogo from '../../assets/muralLogo.png'

function Challenge({api,graph,graphProfile, user}) {
  const {push} = useHistory();
  const {id} = useParams();
  const [challenge, setChallenge] = useState({})
  const [ideas, setIdeas] = useState([])

  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    async function getChallenge () {
      const {data} = await api.get(`/challenges/${id}`)

      if (data && data.stakeholders.length > 0) {
        console.log(data.stakeholders)
        let stakeholders = [];
        for (let stakeholder of data.stakeholders) {
          const valid = uuidValidate(stakeholder.adId)
          if (valid) {
            const pic = await getUserPicture({ id: stakeholder.adId }, api)
            stakeholder.pic = pic
          }
          stakeholders.push(stakeholder)
        }
        console.log(stakeholders)
        data.stakeholders = stakeholders
      }
      const is = await api.get(`/ideas/challenge/${data._id}`)
      console.log(is)
      setIdeas(is.data.reverse())
      setChallenge(data)
    }
    getChallenge()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    console.log(user)
  },[user])


  return (
    <>
    {!challenge._id && (
      <>
        <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
      </>
  )}
    {challenge._id && (
      <>
        <div style={{
          display:'flex',
          paddingLeft:'32px',
          marginTop:'52px',
          paddingRight:'32px',
        }}>
          <div style={{
            display:'flex',
            flexDirection:'column'
          }}>
            <h1 style={{ margin:0, marginBottom:'8px'}}>
              {challenge.title}
            </h1>
            <div style={{display:'flex'}}>
              <SmallChip style={{backgroundColor:getChallengeTypeColor(challenge.type), marginTop:'0'}} value={capitalize(challenge.type)}/>
              {(challenge.sectors && challenge.sectors.length > 0) && (
                <span style={{display:'flex', alignItems: 'center'}}>
                {challenge.sectors.map(sector => (
                  getSectorIcon(sector,'bottom')
                ))}
              </span>
              )}

            </div>
            {challenge.client && (
              <div style={{display:'flex'}}>
                <SmallChip value={challenge.client}></SmallChip>
              </div>
            )}
            {challenge.value.amount && (
              <h2 onMouseOver={(e) => e.stopPropagation() } style={{color:'rgba(0,0,0,0.8)', fontWeight:'400',fontSize:'12px',lineHeight:'14px',margin:0,marginTop:'5px'}}>{challenge.value ? `Value ${new Intl.NumberFormat('en-GB',{style:'currency',currency: 'GBP'}).format(challenge.value.amount)}` : ''}</h2>
            )}
            <h3 style={{fontSize:'15px',margin:'0',marginTop:'5px'}}>Stakeholders</h3>
            <div style={{margin:0, marginTop: '5px',overflowX:'scroll',flexDirection:'row', flexWrap:'wrap', display:'flex',alignItems:"center", fontWeight:'400', fontSize:'20px', color:'rgba(0, 0, 0, 0.8)'}}>
              {challenge.stakeholders.length > 0 && (
                <>
                  {challenge.stakeholders && challenge.stakeholders.map((stakeholder) => (
                    <Chip onClick={(e) => {
                      e.stopPropagation()
                      copy(stakeholder.email)
                      enqueueSnackbar(`Copied ${stakeholder.email} to clipboard`)

                    }} style={{marginLeft:'5px', marginTop:'3px',marginBottom:'3px'}} label={stakeholder.name} avatar={
                      <img
                        alt="Logo"
                        src={stakeholder.pic ? stakeholder.pic : (`//logo.clearbit.com/${stakeholder.email.split("@")[1]}?size=24`)}
                        onError={(e) => {
                          e.target.style.width = 0
                          e.target.style.border = 'none'
                        }}
                        style={{borderRadius:'100%', objectFit:'contain'}}/>} />
                  ))}
                </>
              )}
            </div>

          </div>
          {((user && (user.admin === true || challenge.stakeholders.some(stakeholder => stakeholder._id === user._id)))) && (
            <div style={{marginLeft:'auto', width:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
              <div onClick={() => push(`/challenges/${id}/edit`)} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'64px',height:'64px',marginTop:'4px',color:'#2465AF'}}><img alt="Edit" style={{width:'24px',filter:'invert(29%) sepia(91%) saturate(1051%) hue-rotate(188deg) brightness(89%) contrast(86%)'}} src={editImg}/> </div>
            </div>
          )}


          {/*{user && user.type === 'internal' && (*/}
          {/*  <div style={{marginLeft:'auto', width:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>*/}
          {/*    <div onClick={() => push(`/challenges/${id}/edit`)} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'100%',height:'100%',marginTop:'4px',color:'#2465AF'}}><img alt="Edit" style={{width:'24px',filter:'invert(29%) sepia(91%) saturate(1051%) hue-rotate(188deg) brightness(89%) contrast(86%)'}} src={editImg}/> </div>*/}
          {/*  </div>*/}
          {/*)}*/}

        </div>

        <div
          style={{
            maxWidth:'800px',
            paddingLeft:'32px',
            paddingRight:'32px'
          }}>
          <div style={{display:'flex',marginTop:'15px',flexDirection:'column'}}>
            {challenge.supportingDocumentation && challenge.supportingDocumentation.map((doc) => (
              <div style={{display:'flex',marginRight:'20px', alignItems:'center',marginTop:'3px'}}>
                <a style={{color:'black', textDecoration:'none',display:'flex',alignItems:'center'}} href={doc.link} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={getDocIcon(doc)}/>
                  <p style={{marginLeft:'5px',overflow:'hidden',marginBottom:'1px',marginTop:'1px'}}>{doc.name}</p>
                </a>

              </div>
            ))}

          </div>
          <p dangerouslySetInnerHTML={{__html: challenge.description}}></p>

          <div style={{display:'flex'}}>
            {ideas.length > 0 && (
              <Button onClick={() => push(`/ideas/new/${challenge._id}`)} style={{marginLeft:'0px',marginRight:'10px'}} title="Post Idea"/>
            )}
            {challenge.muralLink && (
              <Button icon={<img style={{width:'16px', height:'16px',marginRight:"8px"}} src={muralLogo} alt="Mural"/> } onClick={() => window.open(challenge.muralLink, '_blank')} mouseOver={async () => {}} mouseLeave={async () => {}} style={{marginLeft:'auto',marginRight:'10px'}} title="Mural Board"/>
            )}
            {/*<Button mouseOver={async () => {}}mouseLeave={async () => {}} style={{backgroundColor:'#6264a7',  boxShadow: '0px 2px 0px #6264a7',marginLeft:'0px',marginRight:'10px'}} title="Teams Channel"/>*/}
          </div>
          <div style={{width:'100%',height:'1px', backgroundColor:'rgba(0,0,0,0.2)',marginTop:'5px'}}></div>
          <div style={{display: 'flex', margin:'10px',flexDirection:'column', alignItems:'center'}}>
            {ideas.map(idea => (
              <IdeaCard idea={idea} graph={graph} api={api} graphProfile={graphProfile}/>
            ))}
            {ideas.length === 0 && (
              <div style={{display:'flex', flexDirection: 'column', alignItems:'center', marginTop:'50px'}}>
                <img alt="No ideas" style={{width:'150px'}} src={newIdeas}/>
                <h2 style={{fontWeight:'400'}}>No ideas yet!</h2>
                <Button onClick={() => push(`/ideas/new/${challenge._id}`)} title="Post an idea"/>
              </div>
            )}
          </div>
        </div>
      </>
    )}
    </>
  );
}

export default Challenge;
