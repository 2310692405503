import React from 'react'
function CardFull({
  key, onClick, style,children
}) {


  const defaultStyle = {
    width:'100%',
    minHeight: "100px",
    backgroundColor:'white',
    margin:'10px',
    boxShadow:'rgb(232, 232, 233) 15px 4px 30px',
    display:'flex',
    cursor:'pointer'
  }

  const s = {...defaultStyle, style}

  return (
      <div
        style={s}
        key={key}
        onClick={onClick}
      >
        {children}
      </div>
  );
}

export default CardFull;