const status = (status) => {
  if (status === "IDEA") return "Idea under development"
  if (status === "REVIEW") return "Peer Review"
  if (status === "FUNDING") return "Seeking Funding"
  if (status === "FUNDED") return "Idea Funded"
  if (status === "DELIVERY") return "Idea in Delivery"
  if (status === "SCALE") return "Idea being Scaled"
  if (status === "BAU") return "Business as Usual"

}

export default status