import React, { useCallback, useMemo, useState } from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import api from '../../utils/api'
import MoonLoader from 'react-spinners/MoonLoader'
import rocketImg from '../../assets/undraw_To_the_stars_qhyy.svg'
import Button from '../../components/Button'
import IdeaCard from './IdeaCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import Input from '../../components/Input'
import FilterButton from '../../components/FilterButton'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'


function Ideas({graph, graphProfile}) {
  const {push} = useHistory();
  const location = useLocation();
  const [ideas, setIdeas] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [search,setSearch] = useState('')
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(0)
  const [filters,setFilters] = useState(false)
  const [status,setStatus] = useState([
    {
      title: "Idea",
      key: 'IDEA',
      active: false
    },
    {
      title: "Peer Review",
      key: 'REVIEW',
      active: false
    },
    {
      title: "Seeking Funding",
      key: 'FUNDING',
      active: false
    },
    {
      title: "Funded",
      key: 'FUNDED',
      active: false
    },
    {
      title: "Delivery",
      key: 'DELIVERY',
      active: false
    },
    {
      title: "Scale up",
      key: 'SCALE',
      active: false
    },
    {
      title: "Business as Usual",
      key: 'BAU',
      active: false
    }
  ])

  const [sectors, setSectors] = useState([

    {
      title: 'Rail',
      key: 'RAIL',
      active: false
    },
    {
      title: 'Highways',
      key: 'HIGHWAYS',
      active: false
    },
    {
      title: 'Aviation',
      key: 'AVIATION',
      active: false
    },
    {
      title: 'Energy',
      key: 'ENERGY',
      active: false
    },
    {
      title: 'Water',
      key: 'WATER',
      active: false
    },
    {
      title: 'Defence',
      key: 'DEFENCE',
      active: false
    },
  ])
  const [types, setTypes] = useState([
    {
      title: "Product",
      key: 'PRODUCT',
      active: false
    },
    {
      title: "Service",
      key: 'SERVICE',
      active: false
    },
    {
      title: "Process",
      key: 'PROCESS',
      active: false
    }
  ]);

  //eslint-disable-next-line no-unused-vars
  const fetchIdeas = useMemo(async () => {
    setLoading(true)
    let ideasReqString = `/ideas?page=${page}`;
      if (search.length > 0) {
        ideasReqString += `&search=${search}`;
      }

      let ts = types.filter(t => t.active)
      if (ts[0]) ideasReqString += `&type=${ts[0].key}`

      let ss = sectors.filter(s => s.active)
      if (ss[0]) ideasReqString += `&sector=${ss[0].key}`

      let st = status.filter(ss => ss.active)
      if (st[0]) ideasReqString += `&status=${st[0].key}`
    let x;
    try {
        x = await api.get(ideasReqString);
      const {data} = x
      setLoading(false)
      const {results, total} = data
      setIdeas(ideas.concat(results));
      if (ideas.concat(results).length < total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setLoading(false)
      return results;
    } catch (e) {
        console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search,types,status,sectors]);

  const handleChange = useCallback(async (x, setter, key) => {
    setIdeas([])
    setPage(0)
    let z = x.map((i) => {
      if (i.key === key) {
        if (i.active === true) {
          i.active = false;
          return i;
        }
        i.active = true;
      } else {
        i.active = false;
      }
      return i;
    })

    setter(...[z]);
  },[])




  return (
    <>
    <>
      <>
        <div
          style={{
          marginLeft:'32px',
          marginTop:'52px',
          marginRight:'32px',
          alignItems:'center',
          display:'flex'
        }}>
          <h1 style={{ color:'#2465AF',marginTop:0}}>
            Ideas
          </h1>
          {ideas.length > 0 && (
            <div style={{marginLeft:'auto', width:'64px',height:'64px',boxShadow:'10px 4px 20px #E8E8E9',background:'#fff', borderRadius:'100%'}}>
              <div onClick={() => push(location.pathname + "/new")} style={{fontSize:'40px', cursor:'pointer', margin:'auto',textAlign:'center',width:'100%',height:'100%',marginTop:'4px',color:'#2465AF'}}>+</div>
            </div>
          )}
        </div>
        <div
          style={{
          padding:'32px',
          flexDirection:'column',
          justifyContent:'center'
          }}>
          <div style={{
            marginBottom:'50px'
          }}>
            <div style={{display:'flex'}}>
              <Input value={searchText} endAdorn={ search.length > 0 ? <FontAwesomeIcon color='#930041' style={{marginTop:'8px', cursor:'pointer'}} onClick={() => {
                setSearch("")
                setSearchText("")
                setPage(0)
                setIdeas([])
              }} icon={faTimesCircle}/> : null} onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => {
                if (e.keyCode === 13){
                  setPage(0)
                  setIdeas([])
                  setSearch(e.target.value)
                }
              }} placeholder="Search"  style={{width:'100%', marginTop:'auto', marginBottom:'auto', marginLeft:'10px', marginRight:'10px'}} inputStyle={{width:'100%', height:'43px', fontSize:'20px'}}/>
              <Button
                variant="primary"
                onClick={(e) => {
                setPage(0)
                setIdeas([])
                setSearch(searchText)
              }} title="Search"/>
              <Button onClick={() => setFilters(!filters)} mouseLeave={(e) => e.target.style.background = 'none'} mouseOver={(e) => e.target.style.background = '#E8E8E9'} style={{marginLeft:'5px',background: filters ? '#E8E8E9': 'none', border:'1px solid #E8E8E9', boxShadow: '0px 2px 0px #E8E8E9'}} title="" icon={<FontAwesomeIcon color="rgba(101, 97, 125, 0.8)" icon={faFilter}/>} />
            </div>
            {filters && (
              <>
                <div style={{display:'flex'}}>
                  <p style={{textAlign:'center',flex:1, marginLeft:'10px',marginBottom:'0px',opacity:'0.6'}}>Type</p>
                  <div style={{
                    margin:'10px',
                    display:'flex',
                    flexDirection:'row',
                    flexWrap:'wrap',
                    flex:10
                  }}>
                    {types.map((item) => (
                      <>
                        <FilterButton onClick={() => handleChange(types, setTypes, item.key)} textStyle={{color: item.active ? 'white' : 'rgba(101, 97, 125, 0.8)'}} style={{backgroundColor:item.active ?'#a1bf37' : 'none'}} title={item.title}/>
                      </>
                    ))}
                  </div>
                </div>
                <div style={{display:'flex'}}>
                  <p style={{textAlign:'center',flex:1,marginLeft:'10px',marginBottom:'0px', opacity:'0.6'}}>Sector</p>
                  <div style={{
                    margin:'10px',
                    display:'flex',
                    flexDirection:'row',
                    flexWrap:'wrap',
                    flex:10
                  }}>
                    {sectors.map((item) => (
                      <FilterButton textStyle={{color: item.active ? 'white' : 'rgba(101, 97, 125, 0.8)'}} style={{backgroundColor:item.active ?'#a1bf37' : 'none'}} onClick={() => handleChange(sectors,setSectors,item.key)} title={item.title}/>
                    ))}
                  </div>
                </div>
                <div style={{display:'flex'}}>
                  <p style={{textAlign:'center', flex:1,marginLeft:'10px',marginBottom:'0px',opacity:'0.6'}}>Status</p>
                  <div style={{
                    margin:'10px',
                    display:'flex',
                    flexDirection:'row',
                    flexWrap:'wrap',
                    flex:10
                  }}>
                    {status.map((item) => (
                      <FilterButton textStyle={{color: item.active ? 'white' : 'rgba(101, 97, 125, 0.8)'}} style={{backgroundColor:item.active ?'#a1bf37' : 'none'}} onClick={() => handleChange(status,setStatus,item.key)} title={item.title}/>
                    ))}
                  </div>
                </div>
              </>
            )}

          </div>


          {loading && (
            <>
            <MoonLoader size={"22px"} color={"#0067c6"} css={{margin:'auto',marginTop:'150px'}}/>
            </>
            )}

          {(ideas.length > 0) && (
            <InfiniteScroll
              style={{
                overflow:'visible',
                width:'100%'
              }}
              dataLength={ideas.length} //This is important field to render the next data
              next={() => {
                setPage(page + 1);
              }}
              scrollableTarget="scrollable"
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
            >
              {ideas.map(idea => (
                idea && (
                  <IdeaCard api={api} graphProfile={graphProfile} graph={graph} idea={idea} ideas={ideas} setIdeas={setIdeas} like={true}/>
                )
                ))}
            </InfiniteScroll>
          )}


          {!loading && ideas.length <= 0 && (
            <>
              <div style={{display:'flex', flexDirection:'column',marginLeft:'auto',marginRight:'auto',alignItems:'center'}}>
                <img alt="Rocket" style={{width:'250px'}} src={rocketImg}/>
                <h2 style={{fontWeight:'400'}}>{ !search ? 'No ideas yet!' : 'No ideas for this search'} </h2>
                <Button to={location.pathname + "/new"} title={`Post a new idea`}></Button>
              </div>
            </>
          )}

        </div>


      </>

    </>
    </>

  );
}

export default Ideas;
