import { validate as uuidValidate } from 'uuid'
import validateEmail from './validateEmail'
import ChipUtils from './ChipUtils'
import getUserPicture from './getUserPicture'
import getUserById from './getUserById'

const addChipSearchInputItem = async(x, selectSetter, searchSetter,chipsSetter, chips, items, itemsSetter, api) => {
  console.log(items)
  console.log(x)
  selectSetter(false)
  searchSetter([])
  if (items.includes(x)) return
  console.log(`Is valid uuid: ${uuidValidate(x)}`)
  if (x.includes("@")) {
    console.log(validateEmail(x))
    if (!validateEmail(x)) return
    chipsSetter(ChipUtils.addChipItem(x, chips))
  }
  if (!x.includes("@")) {
    if (!uuidValidate(x)) return
    const user = await getUserById({id: x}, api)
    const pic = await getUserPicture({id: user.adId},api)
    user.pic = pic
    console.log(user)
    chipsSetter(ChipUtils.addChipItem(user, chips))
  }
  itemsSetter([...items,x])
}

export default addChipSearchInputItem