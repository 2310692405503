import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'
import RichInput from '../../components/RichInput'
import Button from '../../components/Button'
import ChipUtils from '../../utils/ChipUtils'
import ChipInput from 'material-ui-chip-input'
import { makeStyles } from '@material-ui/core/styles'
import { validate as uuidValidate } from 'uuid';
import Chip from '@material-ui/core/Chip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons'
import validateEmail from '../../utils/validateEmail'
import { useParams, useHistory } from 'react-router-dom'
import getBase64 from '../../utils/getBase64'
import asyncForEach from '../../utils/asyncForEach'
import addChipSearchInputItem from '../../utils/addChipSearchInputItem'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox, ListItemText } from '@material-ui/core'
import capitalize from '../../utils/capitalize'
import RichTextEditor from 'react-rte';

const useStyles = makeStyles((theme) => ({
  txt: {
    minHeight: '36px',
    height:'100%',
    maxWidth:'800px',
    width:'100%',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: "#fff",
    lineHeight: '22px',
    paddingLeft: '8px',
    color: 'rgba(0, 0, 0, 0.8)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
    ':focus': {
      border: '1px solid #DCEAF5',
    },
    boxShadow:'rgb(232, 232, 233) 15px 4px 150px'
  },
}));


function NewChallenge({type, update, graphKey,graph,api}) {
  const classes = useStyles();
  const {id} = useParams();
  const {push} = useHistory()



  const [stakeholderSelect, setStakeholderSelect] = useState(false);
  const [title, setTitle] = useState('')
  const [sectors, setSectors] = useState([])
  const [client, setClient] = useState('')
  const [stakeholders, setStakeholders] = useState([])
  const [stakeholderChips, setStakeholderChips] = useState([])
  const [supportingDocumentation, setSupportingDocumentation] = useState([])
  const [muralLink, setMuralLink] = useState('')
  const [description, setDescription] = useState(RichTextEditor.createEmptyValue())
  const [contract, setContract] = useState('')
  const [t, setT] = useState('')
  const [value, setValue] = useState({
    amount: null,
    currency: 'GBP'
  })
  const [searchStakeholders,setSearchStakeholders] = useState([]);
  const [reqPending, setReqPending] = useState(false)
  const [uploadingDocs, setUploadingDocs] = useState(false)


  const getChallenge = async() => {
    if (update) {
      const {data} = await api.get(`/challenges/${id}`)
      console.log(data)
      setTitle(data.title)
      setClient(data.client)
      setSectors(data.sectors)
      setStakeholders(data.stakeholders)
      setSupportingDocumentation(data.supportingDocumentation)
      setMuralLink(data.muralLink)
      setT(data.type)
      setDescription(RichTextEditor.createValueFromString(data.description,'html'))
      setContract(data.contract)
      setValue(data.value)
      await initStakeholders(data.stakeholders)
    }
  }

  useEffect(() => {
      getChallenge()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[update])


  const uploadFiles = async (e) => {
    setUploadingDocs(true)
    const files = []
    const f = e.target.files

    let docs = await getBase64(e.target.files)
    docs = docs.map(doc => doc.split(",")[1])
    await asyncForEach(f, async(file,i) => {
      const res = await api.post('/file',{
        filename:file.name,
        data:docs[i]
      })
      files.push({
        name: file.name,
        type: file.type,
        link: res.data.url
      })
    })
    console.log(files)
    setSupportingDocumentation([...supportingDocumentation,...files])
    setUploadingDocs(false)
  }


  const updateChallenge = async() => {
    setReqPending(true)
    const vals = {
      title,
      sectors,
      client,
      stakeholders,
      supportingDocumentation,
      muralLink,
      description: description.toString('html'),
      contract,
      value
    }
    console.log(vals)
    try {
      const res = await api.put(`/challenges/${id}`,vals)
      setReqPending(false)
      push(`/challenges/${id}`)
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }

  const addChallenge = async() => {
    setReqPending(true)
    const vals = {
        title,
        type:type.toUpperCase(),
        sectors,
        client,
        stakeholders,
        supportingDocumentation,
        muralLink,
        description: description.toString('html') ,
        contract,
        value
      }
      console.log(vals)
      try {
        const res = await api.post('/challenges',vals)
        console.log(res)
        setReqPending(false)
        push(`/challenges/${res.data._id}`)
        console.log(res)
      } catch (e) {
        console.log(e)
      }
  }

  const getUserPicture = async (id) => {
    try {
      const pic = await graph.get(`/users/${id}/photo/$value`, {responseType:'blob'})
      return URL.createObjectURL(pic.data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const s = [
    "RAIL",
    "HIGHWAYS",
    "AVIATION",
    "ENERGY",
    "WATER",
    "DEFENCE",
  ]
  const initStakeholders = async (shs) =>{
    console.log(shs)
    let chips = []
    for (let sh of shs) {
      console.log(`Is valid uuid: ${uuidValidate(sh)}`)
      //stakeholders contains ms account ids or arrays
      if (typeof sh === 'string' && sh.includes("@")) {
        console.log(validateEmail(sh))
        if (!validateEmail(sh)) return
        chips = ChipUtils.addChipItem(sh, chips)
      }
      if (typeof sh === 'object' && sh.adId) {
        const pic = await getUserPicture(sh.adId)
        sh.pic = pic
        chips = ChipUtils.addChipItem(sh, chips)
      } else {
        console.log(sh)
        chips = ChipUtils.addChipItem(sh, chips)
      }
      console.log(chips)
      setStakeholderChips(chips)
    }

  }


  const onStakeholderChange = async (e) => {
    setSearchStakeholders([])
    console.log(e.target.value)
    if (e.target.value.length <= 2) {
      setStakeholderSelect(false)
      return
      }
      try {
        const res = await graph.get(`/users?$search="displayName:${e.target.value}"&$orderby=displayName&$count=true`)
        console.log(res.data.value)
        if (res.data.value.length <= 0) setStakeholderSelect(false)

        let users = [];
          for (let user of res.data.value) {
            setStakeholderSelect(true)
            users.push(user)
          }
        setSearchStakeholders(users)

      } catch (e) {
        console.log(e)
      }

  }

  return (
    <>
      <div style={{
        display: 'flex',
        marginLeft: '32px',
        marginTop: '52px',
        marginRight: '32px',
        alignItems: 'center'
      }}>
        <h1 style={{ color: '#2465AF',marginTop:'0px' }}>
          {!update ? `New ${type.toLowerCase()} challenge` : `Edit challenge`}
        </h1>


      </div>
      <div style={{ padding: '32px', display: 'flex', flexDirection: 'column' }}>
        <Label color="rgba(0, 0, 0, .8)" text="Title"/>
        <Input endAdorn={<p style={{color:'rgba(0,0,0,0.6)'}}>{90 - title.length}</p>} style={{alignSelf:'left'}} inputStyle={{maxWidth:'800px', width:'100%'}} value={title} onChange={(e) => {if(e.target.value.length <= 90)  setTitle(e.target.value)}} placeholder="Title your challenge (in less than 90 characters)" />
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Sectors"/>
        <Select
          disableUnderline
          style={{
            height: '36px',
            width: '340px',
            display: 'block',
            outline: 'none',
            border: '1px solid #FFFFFF',
            boxSizing: 'border-box',
            borderRadius: '5px',
            paddingRight:'20px',
            paddingLeft:'8px',
            fontSize: '18px',
            backgroundColor: "#fff",
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.8)',
            '::placeholder': {
              color: 'rgba(0, 0, 0, .5)',
            },
            ':focus': {
              border: '1px solid #DCEAF5',
            },
            '::after': {
              border:'5px solid black'
            },
            boxShadow:'rgb(232, 232, 233) 15px 4px 150px',
          }}
          value={sectors}
          onChange={(e) => setSectors(e.target.value) }
          multiple={true}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Cross Sector</em>;
            }
            let  x = selected.map(y => capitalize(y))
              return x.join(', ')
          }}
          styles={{ width: '150px' }}
          displayEmpty>

          <MenuItem value="" disabled>
            Cross Sector
          </MenuItem>
        {s.map((sector) => (
          <MenuItem key={sector} value={sector}>
            <Checkbox style={{color:'#2465AF'}} checked={sectors.indexOf(sector) > -1} />
            <ListItemText style={{fontFamily:'Arial'}}  primary={capitalize(sector)} />
          </MenuItem>
        ))}
      </Select>
        {((type && type.toLowerCase() === 'client') || t.toLowerCase() === 'client') && (
          <>
            <Label style={{}} color="rgba(0, 0, 0, .8)" text="Client Organisation"/>
            <Input value={client} onChange={(e) => setClient(e.target.value)} style={{ width: '250px', alignSelf:'left' }}
                   placeholder="Enter the challenge client"/>
          </>
        )}
        {!update && type.toLowerCase() === 'contract' && (
          <>
            <Label style={{}} color="rgba(0, 0, 0, .8)" text="Contract"/>
            <Input value={contract} onChange={(e) => setContract(e.target.value)} style={{ width: '100%',alignSelf:'left' }}
                   placeholder="Enter the challenge contract"/>
          </>
        )}

        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Stakeholders"/>
        <ChipInput
          fullWidth
          onUpdateInput={(e) => onStakeholderChange(e)}
          chipRenderer={({chip}) => {
            return (
              <Chip
                key={chip._id || chip.id || chip}
                style={{marginLeft:'3px',marginRight:'3px', backgroundColor: '#2465AF',marginTop:'5px', marginBottom:'5px'}}
                avatar={
                  <img
                    alt="Logo"
                    src={chip.pic ? chip.pic : (`//logo.clearbit.com/${chip.email ? chip.email.split("@")[1] : chip.split("@")[1] }?size=24`)}
                    onError={(e) => {
                      e.target.style.width = 0
                      e.target.style.border = 'none'
                      e.target.style.display = 'none'
                    }}
                    style={{borderRadius:'100%', objectFit:'contain',backgroundColor:'white'}}/>}
                label={chip.name || chip.displayName || chip}
                color="primary"
                onDelete={() =>{
                  setStakeholderChips(ChipUtils.deleteChipItem(chip,stakeholderChips))
                  setStakeholders(ChipUtils.deleteChipItem(chip,stakeholders))
                }}
                deleteIcon={<FontAwesomeIcon icon={faTimesCircle}/>}
              />
            )
          }
          }
          value={stakeholderChips}
          placeholder="Enter the challenge stakeholders"
          disableUnderline={true}
          className={classes.txt}
          name='stakeholders'
          dataSource={stakeholderChips}
          dataSourceConfig={{name:'name',pic:'pic'}}
          onBeforeAdd={(chip) => addChipSearchInputItem(chip,setStakeholderSelect,setSearchStakeholders,setStakeholderChips,stakeholderChips,stakeholders,setStakeholders,api)}
          onDelete={(chip) => {
            setStakeholderChips(ChipUtils.deleteChipItem(chip, stakeholderChips))
            setStakeholders(ChipUtils.deleteItem(chip, stakeholders))
          }}

        >
        </ChipInput>
        {stakeholderSelect && (
          <div style={{
            width: '100%',
            maxWidth: '800px',
            backgroundColor: 'white',
            height: '200px',
            boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
            overflow: 'scroll',
          }}>
            {searchStakeholders.map(stakeholder => (
              stakeholder.mail && (
                <div
                  key={stakeholder.id}
                  onClick={() => addChipSearchInputItem(stakeholder.id,setStakeholderSelect,setSearchStakeholders,setStakeholderChips,stakeholderChips,stakeholders,setStakeholders,api)} style={{
                  width: '100%',
                  borderBottom: '1px solid rgb(232, 232, 233)',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor:'pointer'
                }}>
                  <p
                    style={{ marginLeft: '8px' }}>{stakeholder.displayName}{stakeholder.mail && (` (${stakeholder.mail.toLowerCase()})`)}</p>
                </div>
              )

            ))}

          </div>
        )}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Value"/>
        <Input value={value.amount} onChange={(e) => setValue({amount:e.target.value,currency:'GBP'})} style={{ width: '250px', alignSelf:'left' }} type="number"
               placeholder="Enter the challenge value"/>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Supporting Documents"/>
        {supportingDocumentation.length > 0 && supportingDocumentation.map(x => (
          <div style={{padding:'5px',margin:"5px"}}>
            <FontAwesomeIcon color='#930041' onClick={() => setSupportingDocumentation(supportingDocumentation.filter(y => y.link !== x.link ))}  style={{marginRight:'5px', cursor:'pointer'}} icon={faMinusCircle}/> {x.name}
          </div>
        ))}
        <Button loading={uploadingDocs} icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>} title="Upload Files" f="docs"  style={{marginLeft:'5px',marginTop:'10px'}} />
        <input hidden id="docs" onChange={(e) => uploadFiles(e)} multiple style={{ marginBottom: '10px' }} type="file"/>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Mural Link"/>
        <Input value={muralLink} onChange={(e) => setMuralLink(e.target.value)}
               style={{ maxWidth: '800px', width: '100%',alignSelf:'left' }} placeholder="Enter a mural link for this challenge"/>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Description"/>
        <RichInput value={description} setter={setDescription}
                   placeholder=""/>


        <Button variant="primary" loading={reqPending} onClick={update ? updateChallenge : addChallenge} style={{ marginLeft: '0',marginTop:'35px' }} title={update? "Save Challenge": "Post Challenge"}/>

      </div>

    </>

  )
}

export default NewChallenge;
