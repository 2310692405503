import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'
import Select from '../../components/Select'
import { Select as MatSelect } from '@material-ui/core'
import Button from '../../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChipUtils from '../../utils/ChipUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons'
import { validate as uuidValidate } from 'uuid'
import validateEmail from '../../utils/validateEmail'
import { makeStyles } from '@material-ui/core/styles'
import getBase64 from '../../utils/getBase64'
import asyncForEach from '../../utils/asyncForEach'
import getUserPicture from '../../utils/getUserPicture'
import capitalize from '../../utils/capitalize'
import MenuItem from '@material-ui/core/MenuItem'
import { Checkbox, ListItemText } from '@material-ui/core'
import TextArea from '../../components/TextArea'

const useStyles = makeStyles((theme) => ({
  txt: {
    minHeight: '36px',
    maxWidth:'800px',
    width:'100%',
    display: 'block',
    marginBottom: '10px',
    outline: 'none',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: "#fff",
    lineHeight: '22px',
    paddingLeft: '8px',
    color: 'rgba(0, 0, 0, 0.8)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
    ':focus': {
      border: '1px solid #DCEAF5',
    },
    boxShadow:'rgb(232, 232, 233) 15px 4px 150px'
  },
}));

function NewIdea({update, graph, api, graphProfile, user}) {
  const classes = useStyles();
  const {challengeId, id} = useParams();
  const {push} = useHistory()

  const [challenges, setChallenges] = useState([])
  const [challengeSelect,setChallengeSelect] = useState(false)
  const [challengeInputDisabled, setChallengeInputDisabled] = useState(false)

  const [challenge, setChallenge] = useState('')
  const [title, setTitle] = useState('')
  const [type, setType] = useState('PRODUCT')
  const [sectors, setSectors] = useState([])
  const [trl, setTrl] = useState(1)
  const [impact, setImpact] = useState(1)
  const [overview, setOverview] = useState('')
  const [tags, setTags] = useState([])
  const [partners, setPartners] = useState([])
  const [source, setSource] = useState('')
  const [status, setStatus] = useState('IDEA')
  const [owners, setOwners] = useState([])
  const [ownerChips, setOwnerChips] = useState([])
  const [ownerSelect, setOwnerSelect] = useState(false)
  const [searchOwners, setSearchOwners] = useState([])
  const [supportingDocumentation, setSupportingDocumentation] = useState([])
  const [coverImage, setCoverImage] = useState({})
  const [muralLink, setMuralLink] = useState('')
  const [challengeText,setChallengeText] = useState('')
  const [uploadingDocs, setUploadingDocs] = useState(false)
  const [uploadingCover, setUploadingCover] = useState(false)
  const [ideaReqPending, setIdeaReqPending] = useState(false)
  // const [feasibilityFactor, setFeasibilityFactor] = useState(0)
  // const [marketSizeFactor, setMarketSizeFactor] = useState(0)
  // const [efficiencyImprovementFactor, setEfficiencyImprovementFactor] = useState(0)
  // const [marketGrowthFactor, setMarketGrowthFactor] = useState(0)
  // const [futurePotentialFactor, setFuturePotentialFactor] = useState(0)
  // const [impactFactor, setImpactFactor] = useState(0)
  // const [solutionDifferentiationFactor, setSolutionDifferentiationFactor] = useState(0)
  // const [technicalCapabilityFactor, setTechnicalCapabilityFactor] = useState(0)
  // const [financeFactor, setFinanceFactor] = useState(0)
  // const [riskFactor, setRiskFactor] = useState(0)

  useEffect(() => {
    const getChallenges = async() => {
      const chals = await api.get(`/challenges?meta=true&search=${challengeText}`)
      setChallenges(chals.data)
    }
    getChallenges()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[challengeText])

  useEffect(() => {
    console.log(challengeId)
    if (challengeId) {
      setChallengeInputDisabled(true)
      setChallenge(challengeId)
      const getChallenge = async() => {
        const chal = await api.get(`/challenges/${challengeId}`)
        setChallengeText(chal.data.title)
      }
      getChallenge()
    }  else {
      const getChallenges = async() => {
        const chals = await api.get('/challenges?meta=true')
        setChallenges(chals.data)
      }
      getChallenges()
    }
    if (!update && graphProfile) handleAddOwner(graphProfile.id)
    if (update) {
      const getIdea = async() => {
        const {data} = await api.get(`/ideas/${id}`)
        console.log(data)
        setTitle(data.title)

        if (data.challenge) {
          const getChallenge = async() => {
            const chal = await api.get(`/challenges/${data.challenge}`)
            console.log(chal)
            setChallenge(chal.data._id)
            setChallengeText(chal.data.title)
          }
          getChallenge()
        }
        setSupportingDocumentation(data.supportingDocumentation)
        setType(data.type)
        setTrl(data.trl)
        setCoverImage(data.coverImage)
        setMuralLink(data.muralLink)
        setStatus(data.status)
        setSectors(data.sectors)
        setPartners(data.partners)
        setOverview(data.overview)
        setSource(data.source)
        setImpact(data.impact)
        setTags(data.tags)
        setOwners(data.owners)
        console.log(data.owners)
        await initOwners(data.owners)
      }
      getIdea()
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[graphProfile])


  const initOwners = async (ownrs) =>{
    console.log(ownrs)
    let chips = []
    for (let own of ownrs) {
      console.log(own)
      if (own.adId) {
        const pic = await getUserPicture({id: own.adId},api)
        console.log(pic)
        own.pic = pic;
        chips = ChipUtils.addChipItem(own, chips)
      } else {
        chips = ChipUtils.addChipItem(own, chips)
      }
      console.log(chips)
      setOwnerChips(chips)
    }

  }

  const onOwnerChange = async (e) => {
    setOwnerSelect(true)
    console.log(e.target.value)
    if (e.target.value.length <= 2) return
    try {
      const res = await graph.get(`/users?$search="displayName:${e.target.value}"&$orderby=displayName&$count=true`)
      let users = [];
      for (let user of res.data.value) {
        users.push(user)
      }
      setSearchOwners(users)

    } catch (e) {
      console.log(e)
    }

  }

  const uploadSupportingDocs = async (e) => {
    setUploadingDocs(true)
    const files = []
    const f = e.target.files

     let docs = await getBase64(e.target.files)
     docs = docs.map(doc => doc.split(",")[1])
     await asyncForEach(f, async(file,i) => {
        const res = await api.post('/file',{
          filename:file.name,
          data:docs[i]
        })
        files.push({
          name: file.name,
          type: file.type,
          link: res.data.url
        })
    })
    setSupportingDocumentation([...supportingDocumentation,...files])
    setUploadingDocs(false)
  }

  const uploadCoverImage = async (e) => {
    setUploadingCover(true)
    const f = e.target.files[0]
    let docs = await getBase64(e.target.files)
    docs = docs.map(doc => doc.split(",")[1])
    console.log(docs)
    await asyncForEach(docs, async(file,i) => {
      const res = await api.post('/file',{
        filename:f.name,
        data:docs[i],
        type: f.type
      })
      console.log(f)
      setCoverImage({
        name: f.name,
        type: f.type,
        link: res.data.url
      })
    })
    setUploadingCover(false)

  }

  const s = [
    "RAIL",
    "HIGHWAYS",
    "AVIATION",
    "ENERGY",
    "WATER",
    "DEFENCE",
  ]

  const getUserById = async(id) => {
      try {
        const {data} = await api.get(`/user/${id}`)
        return data
      } catch (e) {
        console.log(e)
      }

  }

  const handleAddOwner = async(owner) => {
    console.log(owner)
    console.log(owners)
    setOwnerSelect(false)
    setSearchOwners([])

    if (owners.includes(owner)) return
    console.log(`Is valid uuid: ${uuidValidate(owner)}`)
    if (owner.includes("@")) {
      console.log(validateEmail(owner))
      if (!validateEmail(owner)) return
      setOwnerChips(ChipUtils.addChipItem(owner, ownerChips))
    }
    if (!owner.includes("@")) {
      if (!uuidValidate(owner)) return
      const user = await getUserById(owner)
      const pic = await getUserPicture({id: user.adId},api)
      user.pic = pic
      console.log(user)
      setOwnerChips(ChipUtils.addChipItem(user, ownerChips))
    }
    console.log(owners)
    setOwners([...owners,owner])
  }

  const postIdea = async() => {
    setIdeaReqPending(true)
    let x = {
      createdBy: graphProfile.id,
      challenge: challenge.length > 0 ? challenge : null,
      title,
      type,
      sectors,
      trl,
      impact,
      tags,
      source,
      owners,
      overview,
      status,
      muralLink,
      supportingDocumentation,
      coverImage: coverImage ? coverImage: null
    }
    Object.keys(x).forEach((key) => (x[key] == null) && delete x[key]);

    console.log(x)
    const idea = await api.post('/ideas',x)
    setIdeaReqPending(false)
    push(`/ideas/${idea.data._id}`)
    console.log(idea)
  }

  const updateIdea = async() => {
    setIdeaReqPending(true)
    const x = {
      challenge: challenge.length > 0 ? challenge : null,
      title,
      type,
      sectors,
      trl,
      impact,
      tags,
      source,
      owners,
      overview,
      status,
      muralLink,
      supportingDocumentation,
      coverImage: coverImage ? coverImage: null
    }
    Object.keys(x).forEach((key) => (x[key] == null) && delete x[key]);
    console.log(x)
    const idea = await api.put(`/ideas/${id}`,x)
    setIdeaReqPending(false)
    console.log(idea)
    push(`/ideas/${idea.data._id}`)

  }

  return (
    <>
      <div style={{
        display:'flex',
        marginLeft:'32px',
        marginTop:'52px',
        marginRight:'32px',
        alignItems:'center'
      }}>
        <h1 style={{ color:'#2465AF'}}>
          {!update ? `New idea`: `Edit idea`  }
        </h1>


      </div>
      <div style={{padding:'32px', display:'flex',flexDirection:'column'}}>
        <Label  color="rgba(0, 0, 0, .8)" text="Challenge"/>
        <Input onChange={(e) => {
          setChallengeSelect(true)
          setChallengeText(e.target.value)}
        }  disabled={challengeInputDisabled} value={challengeText} onFocus={() => setChallengeSelect(true)} onBlur={(e) => {
          if (challenge === '') setChallengeText('')
          setChallengeSelect(false)
        }}  inputStyle={{width:'100%'}} style={{maxWidth:'800px', width:'100%', zIndex:'999',alignSelf:'left'}} placeholder="Enter the associated challenge" />
          {challengeSelect && (
            <div style={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              height: '200px',
              boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
              overflow: 'scroll',
            }}>
              {challenges.map(challenge => (
                challenge._id && (
                  <div
                    key={challenge._id}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setChallenge(challenge._id)
                      setChallengeText(challenge.title)
                      setChallengeSelect(false)
                    }}
                    style={{
                      width: '100%',
                      borderBottom: '1px solid rgb(232, 232, 233)',
                      height: '36px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor:'pointer'
                    }}>
                    <p
                      style={{ marginLeft: '8px' }}>{challenge.title}</p>
                  </div>
                )
              ))}
            </div>
          )}

        <div style={{width:'100%', maxWidth:'800px', height:'1px', backgroundColor:'rgba(0, 0, 0, .4)',marginBottom:'12px',marginTop:'12px'}}></div>
        <Label  color="rgba(0, 0, 0, .8)" text="Title"/>
        <Input endAdorn={<p style={{color:'rgba(0,0,0,0.6)'}}>{90 - title.length}</p>} style={{alignSelf:'left'}} inputStyle={{maxWidth:'800px', width:'100%'}} value={title} onChange={(e) => {if(e.target.value.length <= 90)  setTitle(e.target.value)}} placeholder="Title your idea (in less than 90 characters)" />
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Type"/>
        <Select
          styles={{width:'150px'}}
          onChange={(e) => setType(e.target.value)}
          value={type}
          options={
          <>
            <option value="PRODUCT">Product</option>
            <option value="SERVICE">Service</option>
            <option value="PROCESS">Process</option>
          </>
        }
        placeholder="Type"></Select>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Sectors"/>
        <MatSelect
          disableUnderline
          style={{
            height: '36px',
            width: '340px',
            display: 'block',
            outline: 'none',
            border: '1px solid #FFFFFF',
            boxSizing: 'border-box',
            borderRadius: '5px',
            paddingRight:'20px',
            paddingLeft:'8px',
            fontSize: '18px',
            backgroundColor: "#fff",
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.8)',
            '::placeholder': {
              color: 'rgba(0, 0, 0, .5)',
            },
            ':focus': {
              border: '1px solid #DCEAF5',
            },
            '::after': {
              border:'5px solid black'
            },
            boxShadow:'rgb(232, 232, 233) 15px 4px 150px',
          }}
          value={sectors}
          onChange={(e) => setSectors(e.target.value) }
          multiple={true}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Cross Sector</em>;
            }
            let  x = selected.map(y => capitalize(y))
            return x.join(', ')
          }}
          styles={{ width: '150px' }}
          displayEmpty>

          <MenuItem value="" disabled>
            Cross Sector
          </MenuItem>
          {s.map((sector) => (
            <MenuItem key={sector} value={sector}>
              <Checkbox style={{color:'#2465AF'}} checked={sectors.indexOf(sector) > -1} />
              <ListItemText style={{fontFamily:'Arial'}}  primary={capitalize(sector)} />
            </MenuItem>
          ))}
        </MatSelect>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Innovation Category"/>
        <Select
          styles={{width:'200px'}}
          value={impact}
          onChange={(e) => setImpact(parseInt(e.target.value))}
          options={
            <>
              <option value={1}>Improvement</option>
              <option value={2}>Progressive</option>
              <option value={3}>Step Change</option>
              <option value={4}>Game Changer</option>
            </>
          } placeholder="Select Idea TRL"></Select>
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Technology Readiness Level (TRL)"/>
        <Select
          styles={{width:'300px'}}
          onChange={(e) => setTrl(parseInt(e.target.value))}
          value={trl}
          options={
            <>
              <option value={1}>TRL 1 - Basic principles</option>
              <option value={2}>TRL 2 - Invention & Research</option>
              <option value={3}>TRL 3 - Proof of Concept</option>
              <option value={4}>TRL 4 - Bench Scale Research</option>
              <option value={5}>TRL 5 - Pilot Scale</option>
              <option value={6}>TRL 6 - Large Scale</option>
              <option value={7}>TRL 7 - Inactive Commissioning</option>
              <option value={8}>TRL 8 - Active Commissioning</option>
              <option value={9}>TRL 9 - Operations</option>
            </>
          } placeholder="Select Idea TRL"></Select>

        {/*{trl > 3 && (*/}
        {/*  <>*/}
        {/*  <Label style={{}} color="rgba(0, 0, 0, .8)" text="Market and Impact"/>*/}
        {/*  <p style={{fontSize:'11px',fontStyle:'italic'}}>What is the addressable market size</p>*/}
        {/*    <AccordianButtons/>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>What are the potential cost savings</p>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>How well does the innovation align to the industrial strategy</p>*/}

        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>How well is the supply chain positioned to deliver the idea</p>*/}

        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>How does the idea stand to impact key customer relationships</p>*/}


        {/*  <Label style={{}} color="rgba(0, 0, 0, .8)" text="Feasibility and Risk"/>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>To what extent is the idea is Break through / Game-changing</p>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>How well is industry placed to delivery the innovation</p>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>To what extent does the client have funding to develop this idea</p>*/}
        {/*    <p style={{fontSize:'11px',fontStyle:'italic'}}>What are the market or regulatory barriers and risks</p>*/}
        {/*  </>*/}
        {/*  )}*/}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="High Level Overview"/>
        <TextArea rows="4" endAdorn={<p style={{color:'rgba(0,0,0,0.6)'}}>{600 - overview.length}</p>} style={{alignSelf:'left'}} inputStyle={{maxWidth:'800px', width:'100%'}} value={overview} onChange={(e) => {if(e.target.value.length <= 600)  setOverview(e.target.value)}} placeholder="Describe your idea (in less than 600 characters)" />

        {/*<Input styles={{maxWidth:'800px', width:'100%'}} placeholder="Enter the challenge stakeholders" />*/}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Tags"/>
        <ChipInput
          fullWidth
          value={tags}
          clearInputValueOnChange={true}
          onUpdateInput={(e) => {
            if (e.target.value.includes(",")) {
                setTags(ChipUtils.addChipItem(e.target.value,tags))
            }
          }}
          onAdd={(chip) => setTags(ChipUtils.addChipItem(chip,tags))}
          placeholder="Categorise your idea with tags eg. BIM, CAV, Procurement (Comma separated)"
          disableUnderline={true}
          className={classes.txt}
          name='tags'
          onDelete={(chip) => {
            console.log(chip)
            setTags(ChipUtils.deleteChipItem(chip, tags))
          }}
        >
        </ChipInput>
        {(user && user.type === 'internal') && (
          <>
            <Label style={{}} color="rgba(0, 0, 0, .8)" text="Partners"/>
            <Input value={partners} onChange={(e) => setPartners(e.target.value)} style={{maxWidth:'800px', width:'100%', alignSelf:'left'}} placeholder="List any partners eg. Network Rail" />
            <Label style={{}} color="rgba(0, 0, 0, .8)" text="Source"/>
            <Input  value={source} onChange={(e) => setSource(e.target.value)}  inputStyle={{width:'100%'}} style={{maxWidth:'800px', width:'100%', alignSelf:'left'}} placeholder="Enter the idea source (eg. Workshop, meeting or collaborative R&D)" />
          </>
        )}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Idea owners"/>
        <ChipInput
          disabled={(user && user.type === 'external')}
          fullWidth
          onUpdateInput={(e) => onOwnerChange(e)}
          chipRenderer={({chip}) => {
            return (
              <Chip
                key={chip.id || chip._id}
                style={{marginLeft:'3px',marginRight:'3px', backgroundColor: '#2465AF'}}
                avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                label={chip.name || chip.displayName || chip}
                color="primary"
                onDelete={() =>{
                  setOwnerChips(ChipUtils.deleteChipItem(chip,ownerChips))
                  setOwners(ChipUtils.deleteItem(chip,owners))
                }}
                deleteIcon={<FontAwesomeIcon icon={faTimesCircle}/>}
              />
            )
          }
          }
          value={ownerChips}
          placeholder="Enter the idea owner/s"
          disableUnderline={true}
          className={classes.txt}
          name='owners'
          dataSource={ownerChips}
          dataSourceConfig={{name:'name',pic:'pic'}}
          onBeforeAdd={handleAddOwner}


        >
        </ChipInput>
        {ownerSelect && (
          <div style={{
            width: '100%',
            maxWidth: '800px',
            backgroundColor: 'white',
            height: '200px',
            boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
            overflow: 'scroll',
          }}>
            {searchOwners.map(owner => (
              owner.mail && (
                <div
                  key={owner.id}
                  onClick={() => handleAddOwner(owner.id)} style={{
                  width: '100%',
                  borderBottom: '1px solid rgb(232, 232, 233)',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor:'pointer'
                }}>
                  <p
                    style={{ marginLeft: '8px' }}>{owner.displayName}{owner.mail && (` (${owner.mail.toLowerCase()})`)}</p>
                </div>
              )

            ))}

          </div>
        )}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Idea Status"/>
        <Select
          disabled={(user && user.type === 'external')}
          styles={{width:'300px'}}
          onChange={(e) => setStatus(e.target.value)}
          value={status}
          options={
            <>
              <option value="IDEA">Idea under development</option>
              <option value="FUNDING">Funding under pursuit</option>
              <option value="APPLIED">Application underway</option>
              <option value="FUNDED">Funded</option>
              <option value="DELIVERY">Project in delivery</option>
              <option value="SCALE">Dissemination / Scale Up</option>
            </>
          } placeholder="Select Idea Status"></Select>
        {(user && user.type === 'internal') && (
          <>
          <Label style={{}} color="rgba(0, 0, 0, .8)" text="Mural Link"/>
          <Input value={muralLink} onChange={(e) => setMuralLink(e.target.value)} style={{maxWidth:'800px', width:'100%', alignSelf:'left'}} placeholder="https://app.mural.co/"/>
          </>
        )}
        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Supporting Documents"/>
        {supportingDocumentation.length > 0 && supportingDocumentation.map(x => (
          <div style={{padding:'5px',margin:"5px"}}>
            <FontAwesomeIcon color="#930041" onClick={() => setSupportingDocumentation(supportingDocumentation.filter(y => y.link !== x.link ))}  style={{marginRight:'5px', cursor:'pointer'}} icon={faMinusCircle}/> {x.name}
          </div>
        ))}
        <Button loading={uploadingDocs} icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>} title="Upload Files" f="docs"  style={{marginLeft:'5px',marginTop:'10px'}} />
        <input hidden id="docs" onChange={(e) => {
          uploadSupportingDocs(e)
        }} multiple type="file"/>


        <Label style={{}} color="rgba(0, 0, 0, .8)" text="Cover Image"/>
        {coverImage && coverImage.link && (
          <div style={{padding:'5px',margin:"5px"}}>
            <FontAwesomeIcon color='#930041' onClick={() => setCoverImage({})}  style={{marginRight:'5px', cursor:'pointer'}} icon={faMinusCircle}/> {coverImage.name}
          </div>
        )}

        <Button loading={uploadingCover} icon={<FontAwesomeIcon icon={faUpload} style={{marginRight:"8px"}}/>} title="Upload Image" f="cover"  style={{marginLeft:'5px',marginTop:'10px'}} />
        <input hidden id="cover" onChange={(e) => uploadCoverImage(e)} style={{ marginBottom: '10px'}} type="file"/>
        <Button loading={ideaReqPending} variant="primary" onClick={() => update ? updateIdea() : postIdea()} style={{marginLeft:'0',marginTop:'35px'}} title={!update ? "Post Idea" : "Update Idea"}/>
      </div>

    </>

  );
}

export default NewIdea;
