import React from 'react';
import { css } from 'glamor';
import MoonLoader from 'react-spinners/MoonLoader';
import { useHistory } from 'react-router-dom';

function Button({
  loading,
  onClick,
  title,
  style,
  textStyle,
  mouseOver,
  mouseLeave,
  icon,
  lowercase,
  to,
  type,
  disabled,
  f,
  variant
}) {

  const defaultStyle = {
    maxHeight:'43px',
    display: 'flex',
    marginTop: 10,
    maxWidth: '340px',
    marginBottom: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
    borderRadius: '5px',
    position: 'relative',
    zIndex: '1',
    opacity: disabled ? 0.4 : 1,
    padding: icon ? '10px 20px' : 'none',
  }

  const defaultTextStyle = {
    margin: 'auto',
    fontSize: '18px',
    padding: !icon ? '10px 20px' : 'none',
    display:'flex',
    alignItems:'center'

  }

  const primaryStyle = {
    background: '#2465AF',
    boxShadow: '0px 2px 0px #2465AF',
  };

  const primaryTextStyle = {
    color: 'white',
  };

  const secondaryStyle = {
    background:'#fbfbfb',
    border:'1px solid #E8E8E9',
    boxShadow: '0px 2px 0px #E8E8E9'
  };

  const secondaryTextStyle = {
    color:'rgba(101, 97, 125, 0.8)'
  };

  let s = {}
  let ts = {}


  if (variant === "primary") {
    s = {...defaultStyle, ...primaryStyle, ...style}
    ts = {...defaultTextStyle, ...primaryTextStyle, ...textStyle}
  }

  if (variant === "secondary" || variant === undefined) {
    s = {...defaultStyle, ...secondaryStyle, ...style}
    ts = {...defaultTextStyle, ...secondaryTextStyle, ...textStyle}
  }



  const defaultMouseOver = (e) => {

  };

  const defaultMouseLeave = (e) => {

  };
  const { push } = useHistory();

  return (
    <label
      type="submit"
      for={f}
      style={{ outline: 0 }}
      onClick={(e) => {
        if (!f) {
          onClick && onClick(e);
          push(to);
        }

      }}
      {...css(s)}
      onMouseOver={mouseOver || defaultMouseOver}
      onMouseLeave={mouseLeave || defaultMouseLeave}
    >
      <div
        style={{ display: 'inherit', margin: 'auto' }}
      >

        <p
          onMouseOver={(e) => e.stopPropagation()}
          onMouseLeave={(e) => e.stopPropagation()}
          {...css(ts)}>
          {(icon && !loading) && icon}
          {loading && (
            <MoonLoader css={{marginRight:'10px'}} size={16} />
          )}
          {!lowercase && title.toString().toUpperCase()} {lowercase && title}

        </p>
      </div>
    </label>
  );
}

export default Button;